import React, { useEffect, useRef, useState } from 'react';
import CanvasBox from '../components/3d-scene/CanvasBox';
import _3dSettings from '../apis/api/_3dSettings';

import { connect } from 'react-redux';
import { setColor } from '../helper/threeHeper';
import { Vector3 } from 'three';
import ReactUI from './reactui';
import { useHistory } from 'react-router-dom'

const RefCanvasBox = React.forwardRef((props, ref) => <CanvasBox ref={ref} {...props} />);
RefCanvasBox.displayName = 'RefCanvasBox';

const Index = (props) => {
  const { roles, authMiddleware } = props;
  const [isIntroduction, setIsIntroduction] = useState(false);
  const controls = useRef();
  let refScene = useRef();
  const history = useHistory();

  useEffect(() => {
    authMiddleware(roles, history);
  }, []);

  let activeObjectIds = [];
  let activeColorModel = {};

  function setActiveObjectIds(ids) {
    let prevIds = [];
    for (const index in activeObjectIds) {
      if (!ids.includes(activeObjectIds[index])) {
        prevIds.push(activeObjectIds[index]);
      }
    }
    handleResetObjectColor(prevIds);
    activeObjectIds = ids;
    handleSetActiveObjectColor();
  }

  function resetActiveColorModel(model) {
    activeColorModel = model;
  }

  const handleResetObjectColor = async (prevIds = []) => {
    refScene.current &&
      (prevIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          setColor(
            objectSelector.userData.color,
            objectSelector.userData.active_alpha,
            objectSelector
          );
          objectSelector.userData.isActive = false;
        }
      });
  };

  const handleSetActiveObjectColor = () => {
    refScene.current &&
      (activeObjectIds || []).forEach((item) => {
        let objectSelector = refScene.current.getObjectByName(item);
        if (objectSelector) {
          let activeColor = activeColorModel['' + item]?.colorCode ?? objectSelector.userData.active_color;
          setColor(
            activeColor,
            objectSelector.userData.active_alpha,
            objectSelector
          );
          objectSelector.userData.isActive = true;
        }
      });
  };

  const renderCanvasBox = () => {
    /*if (!(!!props.assets.length && !!props.hotspots.length && Object.keys(props.settings).length && props.fbxs.length && props.pagesSettings != undefined))
      return <div/>;*/

    if (!(props.locations != undefined && !!props.locations.length && !!props.hotspots.length && props.settings != undefined && Object.keys(props.settings).length && props.pagesSettings != undefined))
      return <div/>;


    if (props.isLoading) {
      return <div/>;
    }

    let ids = [];

    return (
        <CanvasBox
          ref={refScene}
          controls={controls}
          objects={props.assets}
          hotspots={props.hotspots}
          activeObjectIds={ids}
          setActiveObjectIds={setActiveObjectIds}
          _3dSetting={props.settings}
          fbxs={props.fbxs}
          pagesSettings={props.pagesSettings}
          isIntroduction={true}
          targetPosition={controls.current?.getCurrentTarget() ?? new Vector3()}
          locations={props.locations}
        />
    )
  }

  return (
    <>
      {renderCanvasBox()}
      <ReactUI
        isIntroduction={isIntroduction}
        controls={controls}
        refScene={refScene}
        setActiveObjectIds={setActiveObjectIds}
        resetActiveColorModel={resetActiveColorModel}
        activeObjectIds={activeObjectIds}
       />
    </>
  );
};

const mapStateToProps = (state) => {
  const { scene } = state;
  return {
    settings: scene.settings,
    assets: scene.assets,
    hotspots: scene.hotspots,
    fbxs: scene.fbxs,
    isLoading: scene.isLoading,
    pagesSettings: scene.pagesSettings,
    locations: scene.locations
  };
};
export default connect(mapStateToProps)(Index);

