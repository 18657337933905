import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_GROUP_ID } from "../../constants/master-data";
import {
  reqSetEditableCustomer,
  reqSetEditableCustomerNote,
} from "../../reduxs/cms/action";

const CustomerRow = (props) => {
  const {
    item,
    nameInput,
    setNameInput,
    emailInput,
    setEmailInput,
    mobileInput,
    setMobileInput,
    companyInput,
    setCompanyInput,
    roleInput,
    setRoleInput,
    userGroupInput,
    setUserGroupInput,
  } = props;
  const dispatch = useDispatch();
  const editableCustomer = useSelector((state) => state.cms.editableCustomer);

  const onEditable = () => {
    dispatch(reqSetEditableCustomer(item));
  };

  const onEditCustomerNote = () => {
    dispatch(reqSetEditableCustomerNote(item));
  };

  if (editableCustomer?.id === item?.id) {
    return (
      <tr
        className="border-0"
        style={{
          display: "table",
          width: "100%",
          tableLayout: "fixed",
        }}
      >
        <td className="col-2">
          <input
            className="custom-input"
            value={nameInput}
            onChange={(e) => setNameInput(e.target.value)}
          />
        </td>
        <td className="col-2 email-column-custom text-one-line">
          <input
            className="custom-input"
            value={emailInput}
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </td>
        <td className="col-1 number-column-custom text-one-line">
          <input
            className="custom-input"
            value={mobileInput}
            onChange={(e) => setMobileInput(e.target.value)}
          />
        </td>
        <td className="col-2 text-one-line">
          <input
            className="custom-input"
            value={companyInput}
            onChange={(e) => setCompanyInput(e.target.value)}
          />
        </td>
        <td className="text-one-line">
          <input
            className="custom-input"
            value={roleInput}
            onChange={(e) => setRoleInput(e.target.value)}
          />
        </td>
        <td className="text-one-line">
          <select
            name="type"
            onChange={(e) => setUserGroupInput(e.target.value)}
            value={userGroupInput}
          >
            <option value={USER_GROUP_ID.INVESTOR}>Investor</option>
            <option value={USER_GROUP_ID.TENANT}>Tenant</option>
          </select>
        </td>
        <td className="text-decoration-underline">
          <p className="text-one-line clickable mb-0">View</p>
        </td>
        <td className="text-decoration-underline ">
          <p
            onClick={onEditCustomerNote}
            className="text-one-line clickable mb-0"
          >
            View
          </p>
        </td>
        <td>
          <div className="form-check form-switch my-n1">
            <input
              className="form-check-input"
              type="checkbox"
              id="customer-state-1"
            />
            <label className="form-check-label" htmlFor="customer-state-1" />
          </div>
        </td>
        <td>
          <button className={`btn btn-more rounded-pill shadow-0`}>...</button>
        </td>
      </tr>
    );
  }

  return (
    <tr
      className="border-0"
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <td className="col-2">{item.name}</td>
      <td className="col-2 email-column-custom text-one-line">{item.email}</td>
      <td className="col-1 number-column-custom text-one-line">
        {item.mobile}
      </td>
      <td className="col-2 text-one-line">{item.company}</td>
      <td className="text-one-line text-capitalize">{item?.role}</td>
      <td className="text-one-line text-capitalize">{item?.userGroup?.name}</td>
      <td className="text-decoration-underline">
        <p className="text-one-line clickable mb-0" onClick={() => {}}>
          View
        </p>
      </td>
      <td className="text-decoration-underline ">
        <p
          className="text-one-line clickable mb-0"
          onClick={onEditCustomerNote}
        >
          View
        </p>
      </td>
      <td>
        <div className="form-check form-switch my-n1">
          <input
            className="form-check-input"
            type="checkbox"
            id="customer-state-1"
          />
          <label className="form-check-label" htmlFor="customer-state-1" />
        </div>
      </td>
      <td>
        <button
          onClick={onEditable}
          className={`btn btn-more rounded-pill shadow-0`}
        >
          ...
        </button>
      </td>
    </tr>
  );
};

export default CustomerRow;
