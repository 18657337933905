import * as Types from './type';
import unitApi from '../../apis/api/unit';
import areaApi from '../../apis/api/area';

const actGetUnitList = (data) => ({
  type: Types.GET_UNIT_LIST,
  data,
});

const actGetUnitArea = (data) => ({
  type: Types.GET_UNIT_AREA_LIST,
  data,
});

const actFilterUnitGrossRent = (data) => ({
  type: Types.FILTER_GROSS_RENT,
  data,
});

const actFilterUnitCentre = (data) => ({
  type: Types.FILTER_CENTRE,
  data,
});

const actFilterUnitLevel = (data) => ({
  type: Types.FILTER_LEVEL,
  data,
});

const actFilterUnitArea = (data) => ({
  type: Types.FILTER_AREA,
  data,
});

const actFilterUnitPrecinct = (data) => ({
  type: Types.FILTER_PRECINCT,
  data,
});

const actIsShowFloorplan = (data) => ({
  type: Types.SET_IS_SHOW_FLOORPLAN,
  data,
});

const actIsShowGallery = (data) => ({
  type: Types.SET_IS_SHOW_GALLERY,
  data,
});

const actIsShowUnitDetail = (data) => ({
  type: Types.SET_IS_SHOW_UNIT_DETAIL,
  data,
});

const actSetSelectedUnit = (data) => ({
  type: Types.SET_SELECTED_UNIT,
  data,
});

const actSetUnitQuery = (data) => ({
  type: Types.SET_UNIT_QUERY,
  data,
});

const actSetIsShowFilter = (data) => ({
  type: Types.SET_IS_SHOW_FILTER,
  data,
});

const actSetIsShowPrecinctDetail = (data) => ({
  type: Types.SET_IS_SHOW_PRECINCT_DETAIL,
  data,
});

export const reqGetUnitList = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actGetUnitList(data));
    })
    .catch(() => {
    });
};

export const reqGetUnitArea = (query) => (dispatch) => {
  return areaApi
    .getAreasList(query)
    .then((data) => {
      dispatch(actGetUnitArea(data));
    })
    .catch(() => {
    });
};

export const reqSetSelectedUnit = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actSetSelectedUnit(''));
  }

  return unitApi
    .getUnitDetail(id)
    .then((data) => {
      dispatch(actSetSelectedUnit(data));
    })
    .catch(() => {
    });
};

export const reqFilterUnitGrossRent = (data) => (dispatch) => {
  return dispatch(actFilterUnitGrossRent(data));
};

export const reqFilterUnitCentre = (data) => (dispatch) => {
  return dispatch(actFilterUnitCentre(data));
};

export const reqFilterUnitLevel = (data) => (dispatch) => {
  return dispatch(actFilterUnitLevel(data));
};

export const reqFilterUnitArea = (data) => (dispatch) => {
  return dispatch(actFilterUnitArea(data));
};
export const reqFilterUnitPrecinct = (data) => (dispatch) => {
  return dispatch(actFilterUnitPrecinct(data));
};

export const reqIsShowFloorplan = (data) => (dispatch) => {
  return dispatch(actIsShowFloorplan(data));
};

export const reqIsShowGallery = (data) => (dispatch) => {
  return dispatch(actIsShowGallery(data));
};

export const reqIsShowUnitDetail = (data) => (dispatch) => {
  return dispatch(actIsShowUnitDetail(data));
};

export const reqSetUnitQuery = (data) => (dispatch) => {
  return dispatch(actSetUnitQuery(data));
};

export const reqSetIsShowFilter = (data) => (dispatch) => {
  return dispatch(actSetIsShowFilter(data));
};

export const reqSetIsShowPrecinctDetail = (data) => (dispatch) => {
  return dispatch(actSetIsShowPrecinctDetail(data));
};
