import React, { useEffect, useState } from "react";
import './index.scss';
import jwtAuth from '../../apis/utils/jwtAuth';
import authApi from '../../apis/api/auth';
import { validateEmail, validatePassword } from '../../helper/validation';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { reqGetCustomerProfile, reqGetUserProfile } from "../../reduxs/user/action";

const HoldingPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.data);

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    redirectIfAuthenticated();
  }, [authUser])

  useEffect(() => {
    if (email || password) {
      validateEmail(email)
      ? setErrorMessage('')
      : setErrorMessage('The email must be a valid email address.');
    validatePassword(password)
      ? setErrorMessage('')
      : setErrorMessage(
          'The password must be minimum eight characters, at least one letter and one number.'
        );
    }
  }, [email, password]);

  const redirectIfAuthenticated = () => {
    if (jwtAuth.checkToken() && authUser) {
      history.push('/');
    }
  };

  const handleLogin = async () => {
    const data = {
      email: email,
      password: password,
    };

    const res = await authApi.signIn(data);

    if (res?.data) {
      jwtAuth.setAccessToken(res.data.token);
      history.push('/');
    } else {
      setErrorMessage('Email not found or password does not match');
    }
  };

  const getUserProfile = async () => {
    try {
      dispatch(reqGetUserProfile());
    } catch (error) {
      return;
    }
  };

  return (
    <div className="wrap-holding-page h-100">
      <div className="position-absolute top-50 start-50 translate-middle">
        <div className={`modal-form`}>
          <h4 className="modal-form__title mb-0 text-uppercase">Welcome to the District</h4>
          <h4 className="modal-form__title mb-4 text-uppercase underline-sm">EYNESBURY Experience App</h4>
          <div className="modal-form__body">
            <div className="form-group">
              <input
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                className="form-control"
                id="email"
                placeholder="Email Address"
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="form-control"
                id="password"
                aria-describedby="passwordHelp"
                placeholder="Enter Password"
              />
            </div>
            <p id="passwordHelp" className="text-danger mt-2 mb-0">
              {errorMessage}
            </p>
            <div className="d-flex align-items-center justify-content-between">
              <button
                type="submit"
                onClick={() => handleLogin()}
                className="btn btn-link ms-auto pe-0 me-0 pb-0 mb-0 text-uppercase text-dark"
              >
                <strong>Continue</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HoldingPage;
