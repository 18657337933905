export const UNIT_ENUM = {
  SQUARE: 'square',
  SQUARE_METER: 'squareMeter',
  ACRE: 'acre',
  HECTARE: 'hectare',
};
export const PROPERTY_TYPE_ENUM = {
  HOUSE: 'house',
  UNIT: 'unit',
  APARTMENT: 'apartment',
  OFFICE: 'office',
  VILLA: 'villa',
  LAND: 'land',
  TOWNHOUSE: 'townhouse',
};
export const AVAILABILITY_STATUS_ENUM = {
  AVAILABLE_NOW: 'available_now',
  RELEASING_SOON: 'releasing_soon',
  SOLD: 'sold',
  RESERVED: 'reserved',
  MUTILPLE_OFFERS: 'multiple_offers',
};
export const MEDIA_TYPE_ENUM = {
  INTERIOR_ID: 'interior_id',
  FLOORPLAN: 'floorplan',
  IMAGE: 'image',
  VIDEO: 'video',
  '360_PANO_TOUR': '360_pano_tour',
};
export const USER_GROUP = {
  INVESTOR: 'investor',
  TENANT: 'tenant',
  ADMIN: 'admin'
};

export const USER_GROUP_ID = {
  INVESTOR: '606d6f356af09f50f367ee23',
  TENANT: '610a4a3351400773f94bfa89',
}

export default {
  UNIT_ENUM,
  PROPERTY_TYPE_ENUM,
  AVAILABILITY_STATUS_ENUM,
  MEDIA_TYPE_ENUM,
  USER_GROUP,
};
