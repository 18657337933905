import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getCategoryList = (data) => client.get(endPoint.CATEGORY_LIST, data);

export default {
  getCategoryList,
};
