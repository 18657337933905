/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useState } from 'react';
import './index.scss';
import preferenceApi from '../../apis/api/preference';
import FavoritesOfCustomer from './favorites-of-customer';

const CustomerSession = () => {
  const { customerId } = useParams();
  const [preference, setPreference] = useState();
  console.log(preference);

  useEffect(() => {
    getCustomerPreference();
  }, [customerId])

  const getCustomerPreference = async () => {
    try {
      const res = await preferenceApi.getCustomerPreference({
        customer: customerId,
      });
      setPreference(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="wrap-customer-session">
      <div className='header container-fluid p-5'>
        <h4 className='fw-bold title-underline'>MICHELLE&apos;S FAVORITES</h4>
      </div>
      <FavoritesOfCustomer items={preference?.units} />
    </div>
  );
};

export default CustomerSession;
