import React, { useRef, useState } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader } from 'react-three-fiber';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import { OrbitCustomControls2 } from './OrbitCustomControls';
import { Vector3, Euler } from 'three';
import { useDispatch } from 'react-redux';
import { PAGES, LAYERS, OBJECT_TYPES, HOTSPOT_TYPES } from '../../constants/options';
import { gsap } from 'gsap';
import { getMediaUrl } from '../../helper/media';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveTransportOption, reqSetIsShowGalleryModal, reqSetIsShowHotspotModal, reqSetSelectedHotspot } from '../../reduxs/home/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import { useSelector } from 'react-redux';
// import { Html } from "@react-three/drei";
import "./style.scss";
import Annotations from './Annotations';
import { Provider } from 'react-redux';
import store from './../../reduxs/store';

extend({ CustomControls });
extend({ OrbitCustomControls2 });

const CanvasBox = React.memo(React.forwardRef((props, refScene) => {
  const {
    _3dSetting,
    controls,
    fbxs,
    hotspots,
    locations,
    isIntroduction,
    objects,
    targetPosition,
    activeObjectIds,
    setActiveObjectIds,
    pagesSettings
  } = props;

  const dispatch = useDispatch();
  const page = useSelector((state) => state.home.page);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);

  const light = useRef();
  let timeVector3 = new Vector3(0, 0, 0);
  let alphaVector3 = new Vector3(0, 0, 0);

  let isCameraAnimation = false;
  let position = new THREE.Vector3();

  let selectedHotspotId;
  let selectedHotspot;
  const [isCameraAnimated, setCameraAnimated] = useState(false);
  let hotspot3Ds = [];
  let hotspotHasChildren = {};
  let pointerDownId;
  let hotspotPointerDownId;

  let meshInstanceMap = {};

  populateMeshInstanceMapKeys(fbxs)
  associateModelsToMap(objects)

  function getFbxFileName(fbx) {
    let name = fbx.name;
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  };

  function getModelFileName(model){
    let name = model['3d_filename'];
    // remove file extension
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  }

  function populateMeshInstanceMapKeys(fbxs) {
    fbxs.forEach(fbx => {
      let entry = { model: fbx, instances: [] };
      let key = getFbxFileName(fbx);
      meshInstanceMap[key] = entry;
    });
  }

   function associateModelsToMap(objects) {
    objects.forEach(obj => {
      // Make assumption that we can remove .fbx from file_name
      let name = getModelFileName(obj);
      if (!meshInstanceMap[name]) {
        console.warn('No FBX File supplied for', obj)
        return
      }
      meshInstanceMap[name].instances.push(obj);
    });
  }

  function handleAreaClick(controls, camLookAtPosition, camPosition) {
    return controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, 2);
  }

  function updateHotspot() {
    for (let i = 0; i < hotspot3Ds.length; i++) {
      let hotspot3D = hotspot3Ds[i];
      if (!hotspot3D) {
        continue;
      }
      let hotspot = hotspot3D.userData;
      let isVisible = true;
      let isSubHotspot = hotspot.parent_id != null;
      if (isSubHotspot) {
        isVisible = hotspot.parent_id == selectedHotspotId;
      } else if (hotspotHasChildren[hotspot.id]) {
        isVisible = hotspot.id != selectedHotspotId;
      }
      hotspot3D.visible = isVisible;
      if (!isVisible) {
        hotspot3D.layers.set(hotspot.layer);
      }
    }
  }

  function threePosition(data) {
    threePosition2(data, position);
    return position;
  }

  function threePosition2(data, vector) {
    vector.x = data.x;
    vector.y = data.z;
    vector.z = -data.y;
  }

  function setColor(color, object3d) {
    object3d.traverse(function (child) {
      if (child instanceof THREE.Material) {
        child.color.set(color);
      } else if (child.material != null) {
        if (child.material instanceof Array) {
          for (var i = 0; i < child.material.length; i++) {
            child.material[i].color.set(color);
          }
        } else {
          child.material.color.set(color);
        }
      }
    });
  }

  function animateAlpha(alpha, object3d) {
    if (alpha == object3d.userData.alpha) {
      return;
    }

    alphaVector3.x = object3d.userData.alpha;
    alphaVector3.y = 0;
    alphaVector3.z = 0;

    gsap.to(alphaVector3, {
      duration: 0.2,
      x: alpha,
      y: 0.0,
      z: 0.0,
      onUpdate: function () {
        setAlpha(alphaVector3.x, object3d);
      },
      onComplete: function () {
        object3d.userData.alpha = alpha;
      },
    });
  }

  function setAlpha(alpha, object3d) {
    object3d.traverse(function (child) {
      if (child instanceof THREE.Material) {
        child.opacity = alpha;
      } else if (child.material != null) {
        if (child.material instanceof Array) {
          for (var i = 0; i < child.material.length; i++) {
            child.material[i].opacity = alpha;
          }
        } else {
          child.material.opacity = alpha;
        }
      }
    });
  }

  const handleClickHotspot = async (hotspot) => {
    if (selectedHotspot != null) {
      if (selectedHotspot.userData.id != hotspot.id) {
        selectedHotspot.material.map = selectedHotspot.userData.texture;
      }
    }
    if (controls.current != null) {
      controls.current.selectedHotspot = selectedHotspot;
    }

    switch (hotspot.link_type) {
      case HOTSPOT_TYPES.VIRTUAL_TOUR_HOTSPOT:
        dispatch(reqSetIsShowHotspotModal(true));
        dispatch(reqSetExploreModal(hotspot.link));
        break;
      case HOTSPOT_TYPES.GALLERY_OF_IMAGES:
        dispatch(reqSetIsShowGalleryModal(true));
        dispatch(reqSetExploreModal(hotspot.link));
        break;
      case HOTSPOT_TYPES.SINGLE_IMAGE:
        dispatch(reqSetIsShowGalleryModal(true));
        dispatch(reqSetExploreModal(hotspot.link));
        break;
      default:
        break;
    }

    if (hotspot.parent_id) {
      return;
    }

    selectedHotspotId = hotspot.id;
    updateHotspot();
  };

  const Hotspot = React.memo((props) => {
    const onPointerOver = () => (controls.current && controls.current.setCursorStyle('pointer'));
    const onPointerOut = () => (controls.current && controls.current.setCursorStyle('grab'));
    const webglHotspots = hotspots.map((hotspot) => {
      hotspot.texture = useLoader(THREE.TextureLoader, getMediaUrl(hotspot.image_path));
      if (hotspot.active_image_path) {
        hotspot.activeTexture = useLoader(THREE.TextureLoader, getMediaUrl(hotspot.active_image_path));
      }
      return hotspot;
    });
    const { selectedHotspotId } = props;
    hotspot3Ds = [];
    hotspotHasChildren = {};

    return (
      <group>
        {webglHotspots.map((hotspot, index) => {
          threePosition2(hotspot.position, position);
          let isVisible = true;
          let isSubHotspot = hotspot.parent_id != null;
          if (isSubHotspot) {
            isVisible = hotspot.parent_id == selectedHotspotId;
            hotspotHasChildren[hotspot.parent_id] = true;
          } else {
            isVisible = hotspot.id != selectedHotspotId;
          }
          return (
            <sprite
              ref={(r) => {
                if (r && r.material) {
                  r.material.map.minFilter = THREE.LinearMipMapNearestFilter;
                  r.material.map.magFilter = THREE.LinearFilter;
                  r.material.precision = 'highp';
                  r.material.map.needsUpdate = true;
                }
                hotspot3Ds.push(r);
              }}
              visible={isVisible}
              layers={isVisible ? hotspot.layer : LAYERS.DISABLE}
              onPointerOver={() => onPointerOver()}
              onPointerOut={() => onPointerOut()}
              userData={hotspot}
              onPointerDown={ () => {
                hotspotPointerDownId = hotspot.id;
               }}
              onPointerUp={ (e) => {
                hotspotPointerDownId == hotspot.id && handleClickHotspot(e);
                hotspotPointerDownId = null;
              }}
              key={index}
              position={[position.x, position.y, position.z]}
              scale={[(hotspot?.scale?.x || 1) * 1.0, (hotspot?.scale?.y || 1) * 1.0, (hotspot?.scale?.z || 1) * 1.0]}
            >
              <spriteMaterial sizeAttenuation={false} fog={false} precision='highp' attach="material" map={hotspot.texture} />
            </sprite>
          );
        })}
      </group>
    );
  });
  Hotspot.displayName = 'Hotspot';

  const RenderInstance = (instance, model) => {
    let isClickable = true

    let use_color = instance.type == OBJECT_TYPES.DO || instance.type == OBJECT_TYPES.FD;
    let use_texture = instance.use_texture;
    let isActive = activeObjectIds.includes(instance.id);

    model.children.map((mesh_, index) => {
      if (mesh_?.material?.color != null && !use_color) {
        let hexString = mesh_.material.color.getHexString();
        Object.assign(instance, {color: `#${hexString}`});
      }

      const userData = {
        alpha: instance.alpha != null ? instance.alpha / 100.0 : 1.0,
        hover_alpha: instance.hover_alpha != null ? instance.hover_alpha / 100.0 : 1,
        active_alpha: instance.active_alpha != null ? instance.active_alpha / 100.0 : 1.0,
        color: instance.color ?? '#999999',
        hover_color: instance.hover_color ?? instance.color,
        active_color: instance.active_color ?? instance.color,
        isActive: isActive,
        layer: instance.layer
      }

      Object.assign(mesh_, { userData: userData, name: instance.id });
    });

    return model.children.map((mesh_, index) => {
      let isTransparency =
        (instance.alpha != null && instance.alpha <= 80.0) ||
        (instance.hover_alpha != null && instance.hover_alpha <= 80.0) ||
        (instance.active_alpha != null && instance.active_alpha <= 80.0);

      if (isActive) {
        setColor(mesh_.userData.active_color, mesh_);
        isTransparency && setAlpha(mesh_.userData.active_alpha, mesh_);
      } else {
        if (!use_texture || use_color) {
          setColor(mesh_.userData.color, mesh_);
        }
        if (!use_texture || isTransparency) {
          setAlpha(mesh_.userData.alpha, mesh_);
        }
      }

      const onPointerOver =
        instance.hover_color != null
          ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle('pointer');
              instance.hover_color && setColor(mesh_.userData.hover_color, mesh_);
              animateAlpha(mesh_.userData.hover_alpha, mesh_);
              mesh_.userData.isHover = true;
            }
          : null;

      const onPointerOut =
        instance.hover_color != null
          ? () => {
              if (pointerDownId && pointerDownId != instance.id) {
                return;
              }
              if (mesh_.userData.isActive) {
                return;
              }
              controls.current && controls.current.setCursorStyle('grab');
              if (mesh_.userData.isHover) {
                setColor(mesh_.userData.color, mesh_);
                animateAlpha(mesh_.userData.alpha, mesh_);
                mesh_.userData.isHover = false;
              }
            }
          : null;

      const onPointerDown = (e) => {
        e.stopPropagation();
        pointerDownId = instance.id;
      };

      const onPointerUp = () => {
        pointerDownId == instance.id && onClick != null && onClick();
        pointerDownId = null;
      };

      const onClick = isClickable
        ? async () => {

            setActiveObjectIds([instance.id]);
            dispatch(reqSetActivePrecinctID(null));

            if (instance.cam_position) {
              const camPosition = threePosition(instance.cam_position);
              const camLookAtPosition =
                instance.cam_focus_point_position != null
                  ? threePosition(instance.cam_focus_point_position)
                  : position;
              handleAreaClick(controls, camLookAtPosition, camPosition);
            }
            if(instance?.modal) {
              dispatch(reqSetIsShowExploreModal(true));
              dispatch(reqSetExploreModal(instance?.modal))
            }
            if(instance?.sub_precinct) {
              dispatch(reqSetActiveTransportOption([instance?.sub_precinct]));
            } else {
              dispatch(reqSetActiveTransportOption([]));
            }
          }
        : null;

      let meshInstance =
        <mesh
          key={index}
          {...mesh_}
          layers={instance.layer != null ? instance.layer : null}
          userData={mesh_.userData}
          name={instance.id}
          onPointerDown={instance.type && onPointerDown}
          onPointerUp={instance.type && onPointerUp}
          onPointerOut={instance.type && onPointerOut}
          onPointerOver={instance.type && onPointerOver}
        />
      return meshInstance;
    })
  }

  function FbxModel() {
    if (!isIntroduction) {
      return <group />;
    }

    return (
      <group ref={refScene}>
         {Object.keys(meshInstanceMap).map((entry) => {
          const targetMap = meshInstanceMap[entry];
          if (!targetMap) return;
          const model = targetMap.model;
          const instances = targetMap.instances;
          return instances.map(instance => { return RenderInstance(instance, model); });
      })}
      </group>
    );
  }

  const AnimationCamera = React.memo((props) => {
    const {animation3dSetting, controls} = props;
    const {
      camera,
    } = useThree();


    const position = new THREE.Vector3();
    const lookAtPosition = animation3dSetting != null && animation3dSetting.cam_focus_position != null ?
     threePosition(animation3dSetting.cam_focus_position) :
     new THREE.Vector3(-102.89578369966134, -1.1178292546754195e-14, 131.5388245709879);
    const targetPosition = animation3dSetting != null && animation3dSetting.cam_position != null ?
    threePosition(animation3dSetting.cam_position) :
    new THREE.Vector3(-92.46747002504912, 260.2837561175679, 391.6135906913746);
    const delta = new Vector3(-200 - targetPosition.x, 270 - targetPosition.y, -630 - targetPosition.z);

    setCameraAnimated(true);

    camera.position.copy(new THREE.Vector3(820 - delta.x, 810 - delta.y, 0 - delta.z));
    camera.updateProjectionMatrix();

    timeVector3.x = 0;
    timeVector3.y = 0;
    timeVector3.z = 0;

    return <group />;
  });
  AnimationCamera.displayName = 'AnimationCamera';

  const CameraControls = React.memo(() => {
    const {
      camera,
      gl,
      raycaster
    } = useThree();
    const domElement = gl.domElement;

    if (isShowFuture) {
      camera.layers.enable(LAYERS.FUTURE);
      raycaster.layers.enable(LAYERS.FUTURE);
    } else {
      camera.layers.enable(LAYERS.EXISTING);
      raycaster.layers.enable(LAYERS.EXISTING);
    }

    gl.info.autoReset = false;
    useThree().gl.setSize(
      Math.min(window.innerWidth, 1280),
      Math.min(window.innerHeight, 720),
      false
    );

    if (isIntroduction) {
      useFrame(({ gl, scene, camera }) => {
        gl.render(scene, camera);
      }, 1);
    }

    useFrame(() => {
      if (controls.current?.needReloadSelectedHotspotId) {
        selectedHotspotId = '';
        updateHotspot();
        controls.current.needReloadSelectedHotspotId = false;
      }
      if (!isCameraAnimation && isCameraAnimated) {
        if (controls != null && controls.current != null) {
          controls.current.update();
        }
        return;
      }
    });

    return (
      <orbitCustomControls2
        ref={controls}
        args={[camera, domElement, [_3dSetting.start_curve_position.x, _3dSetting.start_curve_position.y, _3dSetting.start_curve_position.z], [_3dSetting.end_curve_position.x, _3dSetting.end_curve_position.y, _3dSetting.end_curve_position.z]]}
        raycaster={raycaster}
        disabledUpdate={false}
        neverUpdate={false}
        autoRotate={false}
        enableDamping={true}
        maxDistance={6640}
        minDistance={2}
        zoomSpeed={2}
        rotateSpeed={0.8}
        minZoom={_3dSetting.minZoom ?? 0.2}
        maxZoom={_3dSetting.maxZoom ?? 8}
        minHeight={10}
        maxHeight={400}
        movingCurveSpeed={_3dSetting.movingCurveSpeed ?? 0.5}
       />
    );
  });
  CameraControls.displayName = 'CameraControls';

  return (
    <>
      <Canvas
        gl={{
          outputEncoding: THREE.sRGBEncoding ,
          logarithmicDepthBuffer : true,
          outputEncoding : THREE.sRGBEncoding,
        }}
        pixelRatio={Math.max(window.devicePixelRatio, 2)}
        camera={{
          position: [
            1020 + _3dSetting.cam_position.x,
            540 + _3dSetting.cam_position.z,
            630 - _3dSetting.cam_position.y,
          ],
          fov: _3dSetting.FOV,
          near: 1,
          far: 10000,
        }}
      >
        {isIntroduction && !isCameraAnimated && <AnimationCamera animation3dSetting={_3dSetting} controls={controls} />}
        <ambientLight intensity={0.2} color={0x2e2e2a} />
        {true && (
          <hemisphereLight
            intensity={0.4}
            skyColor={0xb1e1ff}
            groundColor={0x2e2e2a}
            position={[0, -10, 0]}
          />
        )}
        <directionalLight
          ref={light}
          intensity={1.6}
          color={0xffffff}
          position={[-1500, 600, 250]}
        />
        {true && <directionalLight intensity={0.7} color={0xffffff} position={[1500, 600, -250]} />}
        <React.Suspense fallback={null}>
          <FbxModel />
          <Provider store={store}>
            { [PAGES.LANDING_PAGE, PAGES.INTERACTIVE_TOUR].includes(page) && <Annotations controls={controls} locations={locations} />}
          </Provider>
        </React.Suspense>
        { <CameraControls />}
      </Canvas>
    </>
  );
}));

CanvasBox.displayName = 'CanvasBox';

export default CanvasBox;
