export const config = {
  center: {
    lat:-37.81332374284449, 
    lng: 144.93791819515795
  },
  zoom: 15.5,
};

export const options = {
  styles: [
    {
      featureType: "all",
      elementType: "geometry",
      stylers: [
        {
          color: "#c3b1d5",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.fill",
      stylers: [
        {
          gamma: "0.89",
        },
        {
          lightness: "-75",
        },
        {
          saturation: "-29",
        },
        {
          weight: "1.69",
        },
        {
          color: "#c6bcd0",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.text.stroke",
      stylers: [
        {
          saturation: -31,
        },
        {
          lightness: -33,
        },
        {
          weight: 2,
        },
        {
          gamma: 0.8,
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "all",
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "45",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.fill",
      stylers: [
        {
          lightness: "-72",
        },
        {
          saturation: "-23",
        },
      ],
    },
    {
      featureType: "administrative",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "geometry",
      stylers: [
        {
          lightness: "-18",
        },
        {
          saturation: "-33",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "labels.text",
      stylers: [
        {
          saturation: "54",
        },
      ],
    },
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "landscape.natural",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          saturation: "-28",
        },
        {
          lightness: "-28",
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          lightness: "-10",
        },
        {
          saturation: "-32",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: "-9",
        },
        {
          lightness: "48",
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "poi.school",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: "47",
        },
        {
          saturation: "7",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [
        {
          saturation: 25,
        },
        {
          lightness: 25,
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.fill",
      stylers: [
        {
          saturation: "37",
        },
        {
          lightness: "-60",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "geometry.fill",
      stylers: [
        {
          visibility: "on",
        },
        {
          saturation: "-14",
        },
        {
          lightness: "-15",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "all",
      stylers: [
        {
          lightness: -20,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          saturation: "-26",
        },
        {
          lightness: "-29",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text",
      stylers: [
        {
          lightness: "68",
        },
        {
          saturation: "-25",
        },
      ],
    },
  ],
};

export const mapStyles = {
  default: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
    ],
  },
  road: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ffd77990" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  parking: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },

      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "transit.line.ferry",
        stylers: [{ weight: 8 }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  ferry: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },

      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.ferry",
        stylers: [{ color: "#ffe5c163" }, { visibility: "on" }, { weight: 4 }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "transit.line.ferry",
        stylers: [{ weight: 8 }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
    ],
  },
  train: {
    poiBoost: {},
    previewOptions: {
      viewport: {
        centerLat: -37.81870073463687,
        centerLng: 144.90524365792925,
        zoom: 14,
      },
    },
    rules: [
      { elementType: "labels.icon", stylers: [{ lightness: -25 }] },
      {
        elementType: "geometry.fill",
        featureType: "administrative",
        stylers: [{ color: "#ff262626" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "administrative",
        stylers: [{ color: "#ff707070" }],
      },
      {
        elementType: "labels.text",
        featureType: "administrative",
        stylers: [{ visibility: "off" }],
      },
      {
        featureType: "administrative.land_parcel",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "landscape",
        stylers: [{ color: "#ff343434" }],
      },
      { featureType: "poi", stylers: [{ visibility: "off" }] },
      {
        elementType: "labels.icon",
        featureType: "poi",
        stylers: [{ color: "#ffffffff" }, { lightness: -100 }],
      },
      {
        elementType: "labels",
        featureType: "road",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "road.arterial",
        stylers: [{ color: "#ff555555" }, { visibility: "on" }],
      },
      {
        elementType: "geometry.fill",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry.stroke",
        featureType: "road.highway",
        stylers: [{ color: "#ff272727" }],
      },
      {
        elementType: "geometry",
        featureType: "road.local",
        stylers: [{ color: "#ff272727" }, { visibility: "on" }],
      },
      {
        elementType: "geometry",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "labels.icon",
        featureType: "transit",
        stylers: [{ lightness: -50 }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "transit",
        stylers: [{ visibility: "off" }],
      },
      {
        elementType: "geometry",
        featureType: "water",
        stylers: [{ color: "#ff2a2a2a" }],
      },
      {
        elementType: "labels.text.fill",
        featureType: "water",
        stylers: [{ color: "#ff354245" }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.rail",
        stylers: [{ color: "#ff7fdaac" }, { visibility: "on" }, { weight: 2 }],
      },
      {
        elementType: "geometry",
        featureType: "transit.line.transit_layer",
        stylers: [{ color: "#ff7fdaac" }, { visibility: "on" }],
      },
    ],
  },
};
