import React, { useEffect, useRef } from 'react';
import './style.scss';
import { PAGES } from '../../constants/options';
import { useState } from 'react';
import LoadingIcon from '../loading-icon';
import { useDispatch } from 'react-redux';
import { reqSetPage } from '../../reduxs/home/action';
import { reqSetIsShowCreateInvestorProfileModal, reqSetIsShowCreateTenantProfileModal, reqSetIsShowUserTypeModal } from '../../reduxs/guide-session/action';
import { useLocation } from 'react-router-dom';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Loading = (props) => {
  const { isLoading, setIsIntroduction } = props;
  const [isShowVideo, setShowVideo] = useState(false);
  const dispatch = useDispatch();
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(false);
  const isRedirect = useQuery().get('redirect');

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
    }
  },[isMuted]);

  useEffect(() => {
    if (isRedirect == 'true' && isLoading == undefined) {
      setTimeout(() => { startDiscover(); }, 100);
    }
  }, [isLoading]);

  const startDiscover = () => {
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
  };

  const onExplore = () => {
    dispatch(reqSetIsShowUserTypeModal(true));
  }

  const onStartSession = () => {
    setShowVideo(true);
    videoRef.current.play();
    dispatch(reqSetIsShowCreateInvestorProfileModal(false));
    dispatch(reqSetIsShowCreateTenantProfileModal(false));
  }

  const onPlayGuidedTourVideo = () => {
    setShowVideo(true);
    videoRef.current.play();
  }

  const onStartInteractiveTour = () => {
    dispatch(reqSetPage(PAGES.LANDING_PAGE));
  };

  const onMuteGuidedTourVideo = () => {
    setIsMuted(!isMuted);
  }

  const renderExploreButton = () => {
    return (
      <div className='wrap-btn-tour'>
        <button className='me-5 btn-guided-tour'>
          <div className='d-flex flex-row h-100'>
            <div className='btn-arrow'><img className='img-fluid' src="/icons/right-vector.svg" alt="" /></div>
            <div className='btn-text' onClick={() => onPlayGuidedTourVideo()}>
              <span>Play Video</span>
            </div>
          </div>
        </button>
        <button className='btn-interactive-tour' onClick={startDiscover}>
          <div className='d-flex flex-row h-100'>
            <div className='btn-arrow'><img className='img-fluid' src="/icons/right-vector.svg" alt="" /></div>
            <div className='btn-text' onClick={() => onStartInteractiveTour()}>
              <span>Interactive Map</span>
            </div>
          </div>
        </button>
      </div>
    )
  }

  return (
    <div id="loading-screen">
      <div className={`wrap-intro ${isShowVideo ? 'hide' : 'show'}`}>
        <div
          id="intro-video"
          style={{ backgroundImage: `url("./images/DJI_0114.png")` }}
        />

        <div className="intro-content container">
          <div className="wrap-text b-fade-text">
          A VISIONARY <br/>
          MASTERPLANNED COMMUNITY
          </div>
          {isLoading ? (
            <LoadingIcon />
          ) : (
            <div className="wrap-button">
              {renderExploreButton()}
            </div>
          )}
        </div>
      </div>
      <div className={`wrap-slide-loading ${isShowVideo ? 'show' : 'hide'}`}>
        {
          (<>
            <video
              ref={videoRef}
              autoPlay={false}
              onEnded={() => startDiscover()}
              preload="auto"
              id="intro-video-2"
            >
              <source src="/uploads/videos/RES2562_Eynesbury_Edit_001_Animatic.mp4" type="video/mp4" />
            </video>
            <div className='wrap-btn-control-video d-flex flex-column'>
              <button className='btn-bordered' style={{marginBottom: '20px'}} onClick={onMuteGuidedTourVideo}>
                <div className='d-flex flex-row h-100'>
                  <div className='btn-arrow'>
                    {
                      isMuted ? <img className='img-fluid' src="/icons/black-right-vector.svg" alt="" /> : <img className='img-fluid' src="/icons/right-vector.svg" alt="" />
                    }
                  </div>
                  <div className='btn-text'>
                    {
                      isMuted ? <span>AUDIO ON</span> : <span>AUDIO OFF</span>
                    }
                  </div>
                </div>
              </button>
              <button className='btn-bordered' onClick={startDiscover}>
                <div className='d-flex flex-row h-100'>
                  <div className='btn-arrow'><img className='img-fluid' src="/icons/right-vector.svg" alt="" /></div>
                  <div className='btn-text'>
                    <span>SKIP</span>
                  </div>
                </div>
              </button>
            </div>

          </>)
        }
      </div>
    </div>
  );
};

export default Loading;
