import React from "react";
import './index.scss';

const About = () => {

  return (
    <div className="wrap-about">
      <div className="about-header container">
        <h1>A MILLION YEARS<br />OF HISTORY</h1>
        <div className="row">
          <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-xs-12 col-12">
            <p>Eynesbury has a long, multilayered history. A history that’s rich in people, pride of place and a proudly independent community spirit. Firstly, there’s the well-preserved pastoralist history. Then there’s the natural history. And finally, there’s the collective history that the current residents create each day. Their lives, stories and experiences.</p>
          </div>
        </div>
      </div>
      <div className="about-content container">
        <div className="row mb-5 bg-gray">
          <div className="wrap-image col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12 p-0"
            style={{ backgroundImage: 'url("/images/about/about4.jpeg")' }}
          />
          <div className="wrap-text col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12 bg-gray">
            <h1 style={{ marginBottom: '41px' }}><span className="fw-bold">ACKNOWLEDGING EYNESBURY&#39;S<br/>TRADITIONAL OWNERS</span></h1>
            <p>The history of this land goes way back way beyond what we see today. Eynesbury is situated on Aboriginal land, which the Wadawurrung People are Traditional Owners of.</p>
            <p> Wadawurrung Traditional Owners occupied this land for tens of thousands of years, caring for Country in the most sensitive and sustainable way.</p>
            <p>Evidence of their Traditional habitation and ancient Culture can be seen throughout Eynesbury including numerous scar trees and artefacts. The grey box forest surrounding Eynesbury provided plentiful native animals for hunting and flora for harvesting.</p>
            <p>We recognise the Wadawurrung People for their continuing cultural connection to this land and pay our respects to their Elders past and present.</p>
          </div>
        </div>
        <div className="row mb-5 bg-gray">
          <div className="wrap-text col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12 bg-gray">
            <h1 style={{ marginBottom: '41px' }}><span className="fw-bold">OUR HERITAGE IS EVERYWHERE</span></h1>
            <p>At Eynesbury, there are over 60 heritage-listed assets dotted about the place. Seriously impressive, hey? Some of these heritage assets are unmissable, like the historic homestead. Then there are those that are a little off the beaten track. But most of them are located within an easy stroll. Why not hit the ground and discover them all? You’ll find info on the Eynesbury heritage trails at the Discovery & Sales Centre.</p>
          </div>
          <div className="wrap-image col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-xs-12 col-12 p-0"
            style={{ backgroundImage: 'url("/images/about/about1.jpeg")' }}
          />
        </div>
      </div>
    </div>
  );
}

export default About;
