import * as type from './type';

const initialState = {
  units: [],
  unitAreas: [],
  selectedUnit: '',
  filterUnitGrossRent: '',
  filterUnitCentre: '',
  filterUnitLevel: '',
  filterUnitArea: '',
  filterUnitPrecinct: '',
  isShowFloorplan: false,
  isShowGallery: false,
  isShowUnitDetail: false,
  unitQuery: {},
  isShowFilter: true,
  isShowPrecinctDetail: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_UNIT_LIST:
      return {
        ...state,
        units: action.data.data,
      };
    case type.GET_UNIT_AREA_LIST:
      return {
        ...state,
        unitAreas: action.data.data,
      };
    case type.FILTER_AREA:
      return {
        ...state,
        filterUnitArea: action.data,
      };
    case type.FILTER_CENTRE:
      return {
        ...state,
        filterUnitCentre: action.data,
      };
    case type.FILTER_GROSS_RENT:
      return {
        ...state,
        filterUnitGrossRent: action.data,
      };
    case type.FILTER_LEVEL:
      return {
        ...state,
        filterUnitLevel: action.data,
      };
    case type.FILTER_PRECINCT:
      return {
        ...state,
        filterUnitPrecinct: action.data,
      };
    case type.SET_IS_SHOW_FLOORPLAN:
      return {
        ...state,
        isShowFloorplan: action.data,
      };
    case type.SET_IS_SHOW_GALLERY:
      return {
        ...state,
        isShowGallery: action.data,
      };
    case type.SET_IS_SHOW_UNIT_DETAIL:
      return {
        ...state,
        isShowUnitDetail: action.data,
      };
    case type.SET_SELECTED_UNIT:
      return {
        ...state,
        selectedUnit: action?.data?.data,
      };
    case type.SET_UNIT_QUERY:
      return {
        ...state,
        unitQuery: action.data,
      };
    case type.SET_IS_SHOW_FILTER:
      return {
        ...state,
        isShowFilter: action.data,
      };
    case type.SET_IS_SHOW_PRECINCT_DETAIL:
      return {
        ...state,
        isShowPrecinctDetail: action.data,
      };
    default:
      return state;
  }
};
