import React, { useEffect, useRef, useState } from 'react';
import { Canvas, useThree, extend, useFrame, useLoader } from 'react-three-fiber';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import * as THREE from 'three';
import { CustomControls } from './CustomControls';
import FPSStats from 'react-fps-stats';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import testObjectApi from '../../apis/api/test-object';
import { Html, useProgress } from '@react-three/drei';

extend({ CustomControls });
const TestScene = React.memo((props) => {
  const { controls, refScene } = props;
  const light = useRef();
  const [fbxObjects, setFbxObjects] = useState([]);
  const [glfxObjects, setGlfxObjects] = useState([]);
  const [glbObjects, setGlbObjects] = useState([]);

  useEffect(async () => {
    let result = await testObjectApi.getAssetsList();
    var a = [];
    var b = [];
    var c = [];
    for (let i = 0; i < result.data.length; i++) {
      let data = result.data[i];
      if (data['3d_filename'].endsWith('fbx')) {
        a.push(data);
      } else if (data['3d_filename'].endsWith('gltf')) {
        b.push(data);
      } else if (data['3d_filename'].endsWith('glb')) {
        c.push(data);
      }
    }
    setFbxObjects(a);
    setGlfxObjects(b);
    setGlbObjects(c);
  }, []);

  function TestCoordinates() {
    var numbers = [];
    for (var i = 10; i < 1000; i += 10) {
      numbers.push(i);
    }
    return (
      <group>
        {numbers.map((item, key) => {
          return (
            <group key={key}>
              <mesh scale={[1, 1, 1]} position={[item, 0, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, item, 0]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
              <mesh scale={[1, 1, 1]} position={[0, 0, item]}>
                <boxBufferGeometry args={[1, 1, 1]} />
                <meshStandardMaterial color={'black'} />
              </mesh>
            </group>
          );
        })}
      </group>
    );
  }

  function Loader() {
    const { progress } = useProgress();
    return (
      <Html center style={{ color: '#fff' }}>
        {progress.toFixed(3)}%
      </Html>
    );
  }

  function FbxModel() {
    var url_string = window.location.href;
    var url = new URL(url_string);

    var name = url.searchParams.get('name');
    if (name == null) {
      name = 'test';
    }

    let optimize_draw_call = url.searchParams.get('draw_call');
    if (optimize_draw_call == null) {
      optimize_draw_call = true;
    }

    let use_texture = url.searchParams.get('use_texture');
    if (use_texture == null) {
      use_texture = true;
    }

    let color = url.searchParams.get('color');
    if (color == null) {
      color = '999999';
    }

    const [...fbxModels] = useLoader(
      FBXLoader,
      fbxObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );

    if (light.current != null) {
      light.current.shadow.needsUpdate = true;
    }

    return (
      <group ref={refScene}>
        {fbxModels.map((item, index) => {
          const object3d = item.clone(true);
          const object = fbxObjects[index];

          const castShadow = object.cast_shadow;
          const receiveShadow = object.receive_shadow;

          let geometries = [];
          let material = new THREE.MeshStandardMaterial();

          material.roughness = 0.95;
          material.metalness = 0.6;

          object3d.traverse(function (child) {
            child.castShadow = castShadow;
            child.receiveShadow = receiveShadow;
            if (child instanceof THREE.Mesh) {
              var geometry = child.geometry.clone();
              let matrix = new THREE.Matrix4();
              let position = new THREE.Vector3();
              let scale = new THREE.Vector3();
              let quaternion = new THREE.Quaternion();

              child.matrix.decompose(position, quaternion, scale);

              if (scale.y < 0.0) {
                scale.y = -scale.y;
              }
              if (scale.x < 0.0) {
                scale.x = -scale.x;
              }
              if (scale.z < 0.0) {
                scale.z = -scale.z;
              }

              matrix.compose(position, quaternion, scale);
              geometry.applyMatrix4(matrix);

              geometries.push(geometry);

              if (use_texture) {
                if (child.material instanceof THREE.Material) {
                  material = child.material.clone();
                } else if (child.material instanceof Array) {
                  if (child.material.length > 0) {
                    material = child.material[child.material.length - 1].clone();
                  }
                }
              }
            }
          });
          const newColor = new THREE.Color(`#${color}`);
          if (!use_texture) {
            material.color = newColor;
          }
          let scene;

          if (optimize_draw_call == true) {
            let mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(geometries, false);
            scene = new THREE.Mesh(mergedGeometry, material);
          } else {
            scene = object3d;
          }
          scene.castShadow = castShadow;
          scene.receiveShadow = receiveShadow;

          if (receiveShadow) {
            scene.updateMatrixWorld();
            light.current.target = scene;
          }
          return (
            <primitive
              key={index}
              object={scene}
              dispose={null}
              position={[0.0, 0.0, 0.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GltfModel() {
    const [...gltfModels] = useLoader(
      GLTFLoader,
      glfxObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group ref={refScene}>
        {gltfModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[20.0, 0.0, 20.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  function GlbModel() {
    const [...glbModels] = useLoader(
      GLTFLoader,
      glbObjects.map((item) => `https://binyan-api.testbox.com.au/binyan/${item['3d_filename']}`)
    );
    return (
      <group ref={refScene}>
        {glbModels.map((item, index) => {
          const object3d = item;
          return (
            <primitive
              key={index}
              object={object3d.scene}
              dispose={null}
              position={[-20.0, 0.0, -20.0]}
              scale={[1, 1, 1]}
              rotation={[0, 0, 0]}
            />
          );
        })}
      </group>
    );
  }

  const CameraControls = () => {
    const {
      camera,
      gl: { domElement },
    } = useThree();

    camera.near = 0.01;
    camera.far = 10000;
    camera.position.copy(new THREE.Vector3(80, 80, 0));

    useFrame(() => {
      if (controls != null && controls.current != null) {
        controls.current.update();
      }
    });

    return (
      <customControls
        ref={controls}
        args={[camera, domElement]}
        autoRotate={false}
        disabledUpdate={false}
        enableDamping={true}
        maxDistance={6640}
        minPolarAngle={0}
        maxPolarAngle={Math.PI / 2}
        rotateSpeed={0.2}
      />
    );
  };

  return (
    <>
      <Canvas gl={{ 
          outputEncoding: THREE.sRGBEncoding ,
          shadowMap: {
            autoUpdate : false,
            type : THREE.PCFSoftShadowMap,
          },
          logarithmicDepthBuffer : true,
          outputEncoding : THREE.sRGBEncoding,
        }}
        shadowMap pixelRatio={window.devicePixelRatio}>
        <CameraControls />
        <ambientLight intensity={0.2} color={0x2e2e2a} />
        <hemisphereLight
          intensity={0.4}
          skyColor={0xb1e1ff}
          groundColor={0x2e2e2a}
          position={[0, -9, 0]}
        />
        <directionalLight
          ref={light}
          intensity={1.2}
          castShadow
          color={0xffffff}
          position={[-3000, 1200, 500]}
          shadow-mapSize-height={2048}
          shadow-mapSize-width={2048}
          shadow-camera-near={100}
          shadow-camera-far={4000}
          shadow-camera-left={-2000}
          shadow-camera-right={2000}
          shadow-camera-top={2000}
          shadow-camera-bottom={-2000}
          shadow-autoUpdate={false}
          shadow-bias={0.1}
        />
        {true && <directionalLight intensity={0.4} color={0xffffff} position={[1500, 600, -250]} />}
        <directionalLight intensity={0.4} color={0xffffff} position={[-250, 600, 1500]} />
        <React.Suspense fallback={<Loader />}>
          {true && <FbxModel />}
          {true && <GltfModel />}
          {true && <GlbModel />}
        </React.Suspense>
        <axesHelper args={[1000]} />
        <TestCoordinates />
      </Canvas>
      <FPSStats />
    </>
  );
});
TestScene.displayName = 'TestScene';

export default TestScene;
