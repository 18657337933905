import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqGetListVirtualModals, reqSetIsShowHotspotModal } from "../../reduxs/home/action";
import './index.scss';

const VirtualGallery = () => {
  const dispatch = useDispatch();
  const virtualModals = useSelector((state) => state.home.virtualModals);

  useEffect(() => {
    dispatch(reqGetListVirtualModals());
  },[]);

  const onShowVirtualModal = (modalId) => {
    dispatch(reqSetIsShowHotspotModal(true));
    dispatch(reqSetExploreModal(modalId))
  }

  const renderListVirtualTours = () => {
    return virtualModals.map((modal, index) => {
      return (
        <div key={index} className="virtual-item">
          <h3>{modal.name}</h3>
          <div className="row">
            {
              (modal.virtual_links || []).map((item, idx) => {
                return (
                  <div key={idx} className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mb-4">
                    <img onClick={() => onShowVirtualModal(modal.id)} className="img-fluid cursor-pointer" src={item.thumbnail} alt="" />
                  </div>
                )
              })
            }
          </div>
        </div>
      )
    })
  }

  return (
    <div className="wrap-virtual-gallery">
      <div className="virtual-gallery-content">
        <div className="virtual-gallery-header container">
          <h1>360 TOUR<br />GALLERY</h1>
        </div>
        <div className="virtual-gallery-body container">
          {renderListVirtualTours()}
        </div>
      </div>
    </div>)
}

export default VirtualGallery;
