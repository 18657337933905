import React from "react";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetIsShowGuidePopup, reqSetIsShowIntroFutureBtn, reqSetPage } from "../../reduxs/home/action";
import './index.scss';
import { BrowserView, MobileView } from 'react-device-detect';

const GuidePopup = () => {
  const dispatch = useDispatch();

  const handleClosePopup = () => {
    dispatch(reqSetIsShowGuidePopup(false))
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
    dispatch(reqSetIsShowIntroFutureBtn(true));
  }

  return (
    <>
      <div className="bg-trim" />
      <div className="wrap-guide-popup">
        <div className="wrap-popup-content h-100">
          <div className="popup-content">
            <div className="popup-body m-0">
              <div className="row">
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 wrap-intro-description">
                  <div className="intro-description">
                    <h4 className="title">DISCOVER EYNESBURY</h4>
                    <div className="description">Lead your own interactive tour of the Eynesbury township through our customised 3D map, animation, videos and imagery.</div>
                  </div>
                  <div onClick={handleClosePopup} className="btn-explore"><img className="img-fluid cursor-pointer" src="./icons/interact.svg" alt="" /></div>
                </div>
                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-6 wrap-intro-action">
                  <BrowserView className="desktop h-100 d-flex flex-column justify-content-around">
                    <div className="item">
                      <div className="icon">
                        <img className="img-fluid" src="./icons/icon_1.svg" alt="" />
                      </div>
                      <div className="text">Left click to move left/right or up/down</div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img className="img-fluid" src="./icons/icon_2.svg" alt="" />
                      </div>
                      <div className="text">Right click to rotate</div>
                    </div>
                    <div className="item">
                      <div className="icon">
                        <img className="img-fluid" src="./icons/icon_3.svg" alt="" />
                      </div>
                      <div className="text">Scroll  to zoom in and out</div>
                    </div>
                  </BrowserView>
                  <MobileView className="h-100 d-flex flex-column justify-content-around">
                    <div className="item"><img className="img-fluid" src="./icons/intro_340.svg" alt="" /></div>
                    <div className="item"><img className="img-fluid" src="./icons/intro_339.svg" alt="" /></div>
                  </MobileView>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>

  )
}

export default GuidePopup;
