import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { actSetEditablePropertyColumn } from '../../reduxs/cms/action';
import { toDateFormat, toDateString } from "../../helper/date";
import { toast } from "react-toastify";

const PropertyRow = (props) => {
  const { data, editType, isEdit } = props;
  const dispatch = useDispatch();
  const { tenancy, availabilityStatus, psm, expiryDate } = data;
  const editablePropertyColumn = useSelector((state) => state.cms.editablePropertyColumn);
  const [tenancyInput, setTenancyInput] = useState(data?.tenancy);
  const [availabilityInput, setAvailabilityInput] = useState(data?.availabilityStatus);
  const [PSMInput, setPSMInput] = useState(data?.psm);
  const [expiryDateInput, setExpiryDateInput] = useState(data?.expiryDate ? data?.expiryDate : "");

  const handleWarningSave = (e) => {
    if (editablePropertyColumn && (editablePropertyColumn?.id !== data?.id)) {
      toast.warning('Please save before editing another property.');
      e.target.blur();
    }
  }

  useEffect(() => {
    if (!isEdit &&
      (tenancy !== tenancyInput ||
        availabilityStatus !== availabilityInput ||
        psm !== PSMInput ||
        expiryDate !== expiryDateInput
      )) {
      setTenancyInput(data?.tenancy);
      setAvailabilityInput(data?.availabilityStatus);
      setPSMInput(data?.psm);
      setExpiryDateInput(data?.expiryDate);
    }
  }, [isEdit, tenancy, tenancyInput, availabilityStatus, availabilityInput, psm, PSMInput, expiryDate, expiryDateInput]);

  useEffect(() => {
    if (tenancy !== tenancyInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        tenancy: tenancyInput,
      }))
    }
  }, [tenancy, tenancyInput])

  useEffect(() => {
    if (availabilityStatus !== availabilityInput) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        availabilityStatus: availabilityInput,
      }))
    }
  }, [availabilityStatus, availabilityInput])

  useEffect(() => {
    if (psm !== Number(PSMInput)) {
      dispatch(actSetEditablePropertyColumn({
        id: data.id,
        psm: PSMInput,
      }))
    }
  }, [psm, PSMInput])

  useEffect(() => {
    if (expiryDateInput) {
      const toISOExpiryDateInput = new Date(expiryDateInput).toISOString();
      if (expiryDate !== toISOExpiryDateInput) {
        dispatch(actSetEditablePropertyColumn({
          id: data.id,
          expiryDate: toISOExpiryDateInput,
        }))
      }
    }
  }, [expiryDate, expiryDateInput])

  return (
    <tr
      className="border-0"
      style={{
        display: "table",
        width: "100%",
        tableLayout: "fixed",
      }}
    >
      <td className="col-1">{data?.level}</td>
      <td>
        {editType !== "tenancy" ? (
          tenancyInput
        ) : (
          <input
            value={tenancyInput}
            className="custom-input"
            onChange={(e) => setTenancyInput(e.target.value)}
            onClick={handleWarningSave}
          />
        )}
      </td>
      <td className="col-2">{data?.tradingName}</td>
      <td className="text-capitalize">
        {editType !== "availability" ? (
          availabilityInput
        ) : (
          <select
            style={{ padding: "2px 2px", fontSize: '18px' }}
            value={availabilityInput}
            onChange={(e) => setAvailabilityInput(e.target.value)}
            onClick={handleWarningSave}
            className="custom-input text-capitalize"
          >
            <option className="text-capitalize" value="value">{data?.availabilityStatus ? data?.availabilityStatus : "value"}</option>
          </select>
        )}
      </td>
      <td className="col-2">{data?.precinct}</td>
      <td className="col-1">{data?.areaSqm}</td>
      <td className="col-1">
        {editType !== "psm" ? (
          PSMInput
        ) : (
          <input
            value={PSMInput}
            onChange={(e) => setPSMInput(e.target.value)}
            className="custom-input"
            onClick={handleWarningSave}
            type="number"
          />
        )}
      </td>
      <td className="col-2">
        {editType !== "expiry" ? (
          toDateString(expiryDateInput)
        ) : (
          <>
            <input
              type="date"
              defaultValue={toDateFormat(expiryDateInput)}
              onChange={(e) => setExpiryDateInput(e.target.value)}
              className="custom-input"
              min="2020-01-01"
              onClick={handleWarningSave}
              style={{ cursor: 'text' }}
            />
          </>
        )}
      </td>
    </tr>
  );
}

export default PropertyRow;
