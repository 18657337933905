import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { reqSetIsShowHotspotModal } from '../../reduxs/home/action';
import * as PANOLENS from 'panolens';
import { ImageLoader, Vector3 } from 'three';

import './index.scss';
import { reqSetExploreModal } from '../../reduxs/explore-modal/action';

const HotspotModal = (props) => {
  const dispatch = useDispatch();

  const modal = useSelector((state) => state.exploreModal.modal);

  const loader = new ImageLoader();
  const [panos, setPanos] = useState([]);
  const [viewer, setViewer] = useState(undefined);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClickCancel = () => {
    viewer?.dispose();
    dispatch(reqSetIsShowHotspotModal(false));
    dispatch(reqSetExploreModal(undefined));
  };

  const onInitPanorama = () => {
    let selector = document.querySelector("#wrap-panorama");
    if (selector != undefined) {
      selector.replaceChildren();
    }
    let testPanos = [];
    if (modal == undefined || modal.virtual_links == undefined) {
      return;
    }
    const length = modal.virtual_links.length;
    if (length <= 0) {
      return;
    }

    for (let index = 0; index < length; index++) {
      testPanos.push(undefined);
    }
    setPanos(testPanos);

    const firstChild = modal.virtual_links[0];
    const image1stURL = firstChild.virtual_link;
    loader.load(image1stURL, function ( image ) {
      let pano = new PANOLENS.ImagePanorama(image);

      panos[0] = pano;
      viewer.add(pano);
      viewer.setPanorama(pano);
      setPanos(panos);

      for (let j = 0; j < firstChild.infospots.length; j++) {
        let spot = firstChild.infospots[j];
        let infospot = new PANOLENS.Infospot(
          spot.scale,
          spot.image
        );

        infospot.position.set( spot.position[0], spot.position[1], spot.position[2] );
        infospot.addEventListener( "click", () => {
          console.log(spot.targetIndex);
          setActiveIndex(spot.targetIndex);
          if (panos[spot.targetIndex] != undefined) {
            viewer.add(panos[spot.targetIndex]);
            viewer.setPanorama(panos[spot.targetIndex]);
          }
        });

        pano.add(infospot);
      }

      for (let index = 1; index < modal.virtual_links.length; index++) {
        const child = modal.virtual_links[index];
        const imageURL = child.virtual_link;

        loader.load(imageURL, function ( image ) {
          const panoOther = new PANOLENS.ImagePanorama(image);

          panos[index] = panoOther;
          //viewer.add(panoOther);
          setPanos(panos);

          for (let j = 0; j < child.infospots.length; j++) {
            let spot = child.infospots[j];
            const infospot = new PANOLENS.Infospot(
              spot.scale,
              spot.image
            );

            infospot.position.set( spot.position[0], spot.position[1], spot.position[2] );
            infospot.addEventListener( "click", function() {
              if (panos[spot.targetIndex] != undefined) {
                viewer.add(panos[spot.targetIndex]);
                viewer.setPanorama(panos[spot.targetIndex]);
                setActiveIndex(spot?.targetIndex);
              }
            });
            infospot.show();

            panoOther.add(infospot);
          }

        }, undefined, function (error) {
          console.error( 'An error happened.' , error);
        });
      };
    }, undefined, function (error) {
      console.error( 'An error happened.' , error);
    });

    let viewer = new PANOLENS.Viewer({
      container: document.querySelector("#wrap-panorama"),
      controlButtons: [],
      autoHideInfospot: false
    });
    viewer.camera.fov = 80;
    viewer.camera.updateProjectionMatrix();

    setViewer(viewer);

    //let control = viewer.getControl();
    //control.noZoom = true;
  }

  useEffect(() => {

    onInitPanorama();

    return () => {
      viewer?.dispose();
    }
  }, [modal]);

  const onClickDotItem = (index) => {
    if (panos[index] == undefined) return;

    const pano = panos[index];
    viewer.add(pano);
    setActiveIndex(index);
    viewer.setPanorama(pano);
  }

  const renderDotted = () => {
    return (
      <div className='dots-container'>
        <div className='wrap-dots'>
          {
            (modal?.virtual_links || []).map((item, index) => {
              return (
                <div key={index} onClick={() => onClickDotItem(index)} className={`dot-item ${ activeIndex === index ? 'active' : '' }`} />
              )
            })
          }
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='main-modal'>
          <div className='bg-image'>
            <div id="wrap-panorama" className="img-panorama" />
            <button onClick={handleClickCancel} className="btn-square">
              <img className='img-fluid' src="/icons/close.svg" alt="" />
            </button>
            {renderDotted()}
          </div>
          <div className='black-content' />
      </div>
    </>
  )
}

export default HotspotModal;
