import React from "react";
import { useState } from "react";
import apiCaller from "../../helper/api-caller";
import './index.scss';

const Contact = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [postCode, setPostCode] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState({});
  const [isSubmmitedForm, setIsSubmittedForm] = useState(false);

  const handleSubmitEnquiry = async () => {
    const err = {};

    if (!firstName) err.firstName = 'Please enter your first name.';
    if (!lastName) err.lastName = 'Please enter your last name.';
    if (!email) err.email = 'Please enter your email.';
    if (!phone) err.phone = 'Please enter your phone.';
    if (!message) err.message = 'Please enter your message.';

    setError(err);

    if (Object.keys(err).length) return;

    try {
      const formData = new FormData();
      formData.append('698353_98941pi_698353_98941', firstName);
      formData.append('698353_98943pi_698353_98943', lastName);
      formData.append('698353_98945pi_698353_98945', email);
      formData.append('698353_98947pi_698353_98947', phone);
      formData.append('698353_98949pi_698353_98949', postCode);
      formData.append('698353_98951pi_698353_98951', message);
      await apiCaller.post('https://info.resimaxgroup.com.au/l/698353/2021-06-11/55gz9j?Project_Name=Eynesbury+PROJECT&ip_address=161.97.147.108', formData);

      setIsSubmittedForm(true);
      resetForm();
    } catch (error) {

    }
  }

  const resetForm = () => {
    setError({});
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhone('');
    setPostCode('');
    setMessage('')
  }

  const getErrorClass = (name) => {
    return Object.keys(error).includes(name) ? 'error' : '';
  }

  const renderForm = () => {
    if (isSubmmitedForm) {
      return (
        <h4 className="text-center text-danger">Thanks for your enquiry. We&#39;ll get back to you shortly.</h4>
      )
    }

    return (
      <div className="row d-flex justify-content-center">
        <div className="col-xxl-8 col-xl-8 col-lg-10 col-md-12 col-xs-12 col-12">
          <div className="row">
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 col-12">
              <div className={`group-input mb-3 ${getErrorClass('firstName')}`}>
                <label className="mb-10" htmlFor="">First Name <span className="text-danger">*</span></label>
                <input onChange={(e) => setFirstName(e.target.value)} className="form-control" type="text" />
                {
                  error?.firstName && (
                    <label className="error-text text-danger d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.firstName}
                    </label>
                  )
                }
              </div>
              <div className={`group-input mb-3 ${getErrorClass('email')}`}>
                <label className="mb-10" htmlFor="">Email <span className="text-danger">*</span></label>
                <input onChange={(e) => setEmail(e.target.value)} className="form-control" type="email" />
                {
                  error?.email && (
                    <label className="error-text d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.email}
                    </label>
                  )
                }
              </div>
              <div className={`group-input mb-3 ${getErrorClass()}`}>
                <label className="mb-10" htmlFor="">Post code</label>
                <input onChange={(e) => setPostCode(e.target.value)} className="form-control" type="text" />
                {
                  error?.postCode && (
                    <label className="error-text d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.postCode}
                    </label>
                  )
                }
              </div>
            </div>
            <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-xs-12 col-12">
              <div className={`group-input mb-3 ${getErrorClass('lastName')}`}>
                <label className="mb-10" htmlFor="">Last Name <span className="text-danger">*</span></label>
                <input onChange={(e) => setLastName(e.target.value)} className="form-control" type="text" />
                {
                  error?.lastName && (
                    <label className="error-text d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.lastName}
                    </label>
                  )
                }
              </div>
              <div className={`group-input mb-3 ${getErrorClass('phone')}`}>
                <label className="mb-10" htmlFor="">Phone <span className="text-danger">*</span></label>
                <input onChange={(e) => setPhone(e.target.value)} className="form-control" type="number" />
                {
                  error?.phone && (
                    <label className="error-text d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.phone}
                    </label>
                  )
                }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-xs-12 col-12">
              <div className={`group-input mb-3 ${getErrorClass('message')}`}>
                <label className="mb-10" htmlFor="">Message <span className="text-danger">*</span></label>
                <textarea onChange={(e) => setMessage(e.target.value)} className="form-control" rows={5} cols={10} />
                {
                  error?.message && (
                    <label className="error-text d-flex align-items-center mt-10" htmlFor="">
                      <img className="img-fluid me-10" src="./icons/info-icon.svg" alt="" />
                      {error?.message}
                    </label>
                  )
                }
              </div>
              <div className="group-btn text-center">
                <button onClick={handleSubmitEnquiry} className="btn-submit-enquiry">Submit Enquiry</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="wrap-contact-page">
      <div className="contact-header">
        <h1>WANT TO DISCOVER MORE?</h1>
        <p>Leave us your details and we&#39;ll be in touch soon.</p>
      </div>
      <div className="contact-form container">
        {renderForm()}
      </div>
    </div>
  );
}

export default Contact;
