import * as type from './type';

const initialState = {
  categories: [],
  activeLocation: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case type.GET_CATEGORIES_LIST:
      return {
        ...state,
        categories: action.data.data,
      };
    case type.SET_ACTIVE_LOCATION:
      return {
        ...state,
        activeLocation: action.data,
      };
    default:
      return state;
  }
};
