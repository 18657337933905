const API_METHOD = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

const post = async(endPoint, data) => {
  const config = {
    method: API_METHOD.POST,
    headers: {
      'Accept': '*/*',
    },
    body: data,
  };

  const fetchResult = await fetch(endPoint, config);

  if ([ 200, 201 ].includes(fetchResult.status)) {
    return payload;
  }

  throw payload;
}

const apiCaller = {
  post,
}

export default apiCaller;
