const ACTION_TYPES = {
  DRAWING: 'drawing', 
  INFO_WINDOW: 'info_window', 
  MODAL: 'modal', 
  PANEL: 'panel',
  MARKER: 'marker',
};

export const POIs = [
  {
    title: "POI 1",
    category: 'parking',
    subcategory: null, 
    id: 1,
    action_types: [
      ACTION_TYPES.DRAWING,
      ACTION_TYPES.INFO_WINDOW, 
      ACTION_TYPES.PANEL,
    ],
    [ACTION_TYPES.DRAWING]: {
      coordinates: [
        { "lat": -37.81867040171876, "lng": 144.94420065243878 },
        { "lat": -37.81958572848202, "lng": 144.9445439751927 },
        { "lat": -37.81900941295452, "lng": 144.9463464196507 },
        { "lat": -37.818161881945805, "lng": 144.94587435086407 },
      ],
    },
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 1</h3><p>Drawing</br>info window</br>sidepanel</p></div>"
    },
    [ACTION_TYPES.PANEL]: {
      content: "<div><h3>POI 1</h3><p>This point should have a: </p><ul><li>Drawing</li><li>info window</li><li>sidepanel</li></ul></div>"
    }
  },
  {
    title: "POI 2",
    category: "parking", 
    subcategory: null, 
    id: 2, 
    action_types: [
      ACTION_TYPES.DRAWING,
      ACTION_TYPES.INFO_WINDOW, 
      ACTION_TYPES.PANEL,
    ],
    [ACTION_TYPES.DRAWING]: {
      coordinates: [
        { "lat": -37.82070470842462, "lng": 144.94464690769266 },
        { "lat": -37.821340335695986, "lng": 144.94499023044656 },
        { "lat": -37.82136576067298, "lng": 144.94544084156107 },
        { "lat": -37.82074284621525, "lng": 144.94522626483987 },
      ],
    },
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 2</h3><p>Drawing</br>info window</br>sidepanel</p></div>"
    },
    [ACTION_TYPES.PANEL]: {
      content: "<div><h3>POI 2</h3><p>This point should have a: </p><ul><li>Drawing</li><li>info window</li><li>sidepanel</li></ul></div>"
    }
  },
  {
    title: "POI 3",
    category: "road",
    subcategory: null, 
    id: 3, 
    action_types: [
      ACTION_TYPES.MARKER, 
      ACTION_TYPES.INFO_WINDOW, 
    ],
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 3</h3><p>info window</br>marker</p></div>"
    },
    [ACTION_TYPES.MARKER]: {
      coordinates: { "lat": -37.82241723003094, "lng": 144.93741069748728 }
    }
  },
  {
    title: "POI 4",
    category: "road",
    subcategory: null, 
    id: 4,
    action_types: [
      ACTION_TYPES.MARKER, 
      ACTION_TYPES.INFO_WINDOW, 
    ],
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 4</h3><p>info window</br>marker</p></div>"
    },
    [ACTION_TYPES.MARKER]: {
      coordinates: { "lat": -37.82086149699347, "lng": 144.94363303268503 }
    }
  },
  {
    title: "POI 5",
    category: "road",
    subcategory: null, 
    id: 5, 
    action_types: [
      ACTION_TYPES.MARKER, 
      ACTION_TYPES.INFO_WINDOW, 
    ],
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 5</h3><p>info window</br>marker</p></div>"
    },
    [ACTION_TYPES.MARKER]: {
      coordinates: { "lat": -37.8129481017543, "lng": 144.94589287061808 }
    }
  },
  {
    title: "POI 6",
    category: "road",
    subcategory: null, 
    id: 6, 
    action_types: [
      ACTION_TYPES.MARKER, 
      ACTION_TYPES.INFO_WINDOW, 
      ACTION_TYPES.PANEL,
    ],
    [ACTION_TYPES.INFO_WINDOW]: {
      content: "<div style='margin: 20px 10px'><h3>POI 6</h3><p>marker</br>info window</br>panel</p></div>"
    },
    [ACTION_TYPES.MARKER]: {
      coordinates: { "lat": -37.830395486901516, "lng": 144.97953414916992 }
    },
    [ACTION_TYPES.PANEL]: {
      content: "<div><h3>POI 6</h3><p>This point should have a: </p><ul><li>Marker</li><li>info window</li><li>sidepanel</li></ul></div>"
    }
  },
  {
    title: "POI 7",
    category: "ferry",
    subcategory: null, 
    id: 7, 
  },
  {
    title: "POI 8",
    category: "train",
    subcategory: null, 
    id: 7, 
  },
];
