import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  reqGetListUnits,
  actSetEditablePropertyColumn,
} from "../../reduxs/cms/action";
import PropertyRow from "./property-row";
import unitApi from "../../apis/api/unit";
import { toast } from "react-toastify";

const editTypes = {
  tenancy: "tenancy",
  availability: "availability",
  psm: "psm",
  expiry: "expiry",
};

const CMSProperties = () => {
  const dispatch = useDispatch();
  const units = useSelector((state) => state.cms.units);
  const editablePropertyColumn = useSelector(state => state.cms.editablePropertyColumn);
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [editType, setType] = useState("");
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListProperties();
  }, []);

  useEffect(() => {
    getListProperties();
  }, [search, isSortAsc])

  const getListProperties = async () => {
    dispatch(
      reqGetListUnits({
        search,
        sortBy: JSON.stringify({
          tradingName: isSortAsc ? 1 : -1,
        }),
      })
    );
  };

  const handleWhenClickEditButton = (title) => {
    setType((prev) => {
      if (prev === title) {
        setIsEdit(!isEdit);
        return "";
      }
      setIsEdit(true);
      return title;
    });
  };

  const handleWhenClickCancelButton = () => {
    dispatch(actSetEditablePropertyColumn(null));
    setIsEdit(false);
    setType("");
  };

  const handleSubmitOnClickSaveButton = async () => {
    try {
      await unitApi.putUnitUpdate(
        editablePropertyColumn?.id,
        editablePropertyColumn
      );
      toast.success("Update property successfully");
      dispatch(reqGetListUnits());
      dispatch(actSetEditablePropertyColumn(null));
      // setIsEdit(false);
      // setType("");
    } catch (error) {
      toast.error(error?.message);
      setIsEdit(false);
      setType("");
    }
  };

  const renderListProperty = () => {
    return (units || []).map((item, index) => {
      return (
        <PropertyRow
          key={index}
          data={item}
          index={index}
          editType={editType}
          isEdit={isEdit}
        />
      );
    });
  };

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">Properties CMS</h1>
        <div className="input-group ms-auto page-header__input-search w-50">
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            className="form-control"
            type="text"
            placeholder="Search..."
          />
        </div>
        <button
          className={`btn shadow-0 ps-xl-5 pe-0 m-0 ms-xl-5 ${!isSortAsc && "sort-desc"
            }`}
          onClick={() => toggleSortAsc(!isSortAsc)}
        >
          Name
          <span className="btn-icon-append ms-3">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"
                fill="#414345"
              />
            </svg>
          </span>
        </button>
      </div>
      <div className="page-body">
        <div className="table-responsive-custom">
          <table className="table-custom">
            <thead>
              <tr
                style={{
                  display: "table",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <th className="col-1 header-level">Level</th>
                <th className="header-tenancy">
                  Tenancy
                  <button
                    className={`btn-edit rounded-pill shadow-0 me-2`}
                    onClick={() => handleWhenClickEditButton("tenancy")}
                  >
                    ...
                  </button>
                </th>
                <th className="col-2 header-training">Trading Name</th>
                <th className="editable">
                  Availability
                  <button
                    className={`btn-edit rounded-pill shadow-0 me-2`}
                    onClick={() => handleWhenClickEditButton("availability")}
                  >
                    ...
                  </button>
                </th>
                <th className="col-2 header-precinct">precinct</th>
                <th className="col-1">area sqm</th>
                <th className="col-1">
                  $ psm
                  <button
                    className={`btn-edit rounded-pill shadow-0 me-2`}
                    onClick={() => handleWhenClickEditButton("psm")}
                  >
                    ...
                  </button>
                </th>
                <th className="col-2 header-expiry">
                  expiry
                  <button
                    className={`btn-edit rounded-pill shadow-0 me-2`}
                    onClick={() => handleWhenClickEditButton("expiry")}
                  >
                    ...
                  </button>
                </th>
              </tr>
            </thead>
            <tbody
              style={{
                display: "block",
                maxHeight: "calc(100vh - 400px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {renderListProperty()}
            </tbody>
          </table>
        </div>
      </div>
      {isEdit && (
        <div className="page-footer d-flex justify-content-end text-decoration-underline">
          <button
            onClick={handleWhenClickCancelButton}
            className="btn fw-bold shadow-0 btn-cancel"
            type="button"
          >
            CANCEL
          </button>
          <button
            className="btn shadow-0 pe-0 fw-bold text-decoration-underline"
            type='button'
            onClick={handleSubmitOnClickSaveButton}
            disabled={!editablePropertyColumn}
          >
            SAVE
          </button>
        </div>
      )}
    </div>
  );
};

export default CMSProperties;
