import ApiClient from "../apiClient";
import { defaultFetcher } from "../utils/fetcher";
import endPoint from "../endPoint";

const client = new ApiClient(defaultFetcher);

const getCustomerList = (data) => client.get(endPoint.CUSTOMER_LIST, data);
const getCustomerDetail = (id, data) =>
  client.get(endPoint.CUSTOMER_DETAIL.replace(":id", id), data);
const createCustomer = (data) => client.post(endPoint.CUSTOMER_CREATE, data);
const updateCustomer = (id, data) =>
  client.put(endPoint.CUSTOMER_UPDATE.replace(":id", id), data);
const deleteCustomer = (id, data) =>
  client.delete(endPoint.CUSTOMER_DELETE.replace(":id", id), data);

const getInvestorList = (data) =>
  client.get(
    endPoint.CUSTOMER_LIST,
    {
      ...data,
      'userGroup[equalId]': '606d6f356af09f50f367ee23',
    }
  );
const getTenantList = (data) =>
  client.get(
    endPoint.CUSTOMER_LIST,
    {
      ...data,
      'userGroup[equalId]': '610a4a3351400773f94bfa89',
    }
  );

export default {
  getCustomerList,
  getCustomerDetail,
  createCustomer,
  updateCustomer,
  deleteCustomer,
  getInvestorList,
  getTenantList,
};
