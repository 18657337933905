import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import _ from "lodash";

import NotesModal from "./notes-modal";
import { useDispatch, useSelector } from 'react-redux';
import { reqGetListCustomers, reqSetEditableCustomer } from '../../reduxs/cms/action';
import CustomerRow from "./customer-row";
import customerApi from "../../apis/api/customer";
import AddNewCustomerModal from "./add-new-customer-modal";

const CMSCustomers = () => {
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.cms.customers);
  const editableCustomer = useSelector((state) => state.cms.editableCustomer);
  const [isShowAddNewCustomerModal, setIsShowAddNewCustomerModal] = useState(false);


  const [nameInput, setNameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [mobileInput, setMobileInput] = useState("");
  const [companyInput, setCompanyInput] = useState("");
  const [roleInput, setRoleInput] = useState("");
  const [userGroupInput, setUserGroupInput] = useState("");
  const [isEditNotes, setIsEditNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const [isSortAsc, toggleSortAsc] = useState(true);
  const [search, setSearch] = useState('');

  useEffect(() => {
    getListCustomers();
  }, []);

  useEffect(() => {
    setNameInput(editableCustomer?.name);
    setEmailInput(editableCustomer?.email);
    setMobileInput(editableCustomer?.mobile);
    setCompanyInput(editableCustomer?.company);
    setRoleInput(editableCustomer?.role);
    setUserGroupInput(editableCustomer?.userGroup?.id);
  }, [editableCustomer]);

  useEffect(() => {
    getListCustomers();
  }, [search, isSortAsc])

  const getListCustomers = () => {
    dispatch(reqGetListCustomers({
      search,
      sortBy: JSON.stringify({
        name: isSortAsc ? 1 : -1,
      }),
    }));
  }

  const handleHideNotesModal = () => {
    setIsEditNotes(false);
    setNotes("");
  };

  const handleSaveNotesModal = (e) => {
    setIsEditNotes(false);
    setNotes("");
  };

  const handleCancel = () => {
    dispatch(reqSetEditableCustomer());
  };

  const handleSaveCustomer = async () => {
    try {
      const defaultValue = customers.find(customer => customer.id === editableCustomer?.id);
      if (_.isEqual(defaultValue, editableCustomer)) {
        toast.warning("No changes to save");
        return;
      }
      await customerApi.updateCustomer(editableCustomer.id, {
        name: nameInput,
        email: emailInput,
        mobile: mobileInput,
        company: companyInput,
        role: roleInput,
        userGroup: userGroupInput,
      });
      toast.success('Updated customer successfully.');
      dispatch(reqSetEditableCustomer());
      dispatch(reqGetListCustomers());
    } catch (error) {
      toast.error(error?.message);
    }
  }

  const renderListCustomers = () => {
    return (customers || []).map((customer, idx) => {
      return (
        <CustomerRow
          key={idx}
          item={customer}
          nameInput={nameInput}
          setNameInput={setNameInput}
          emailInput={emailInput}
          setEmailInput={setEmailInput}
          mobileInput={mobileInput}
          setMobileInput={setMobileInput}
          companyInput={companyInput}
          setCompanyInput={setCompanyInput}
          roleInput={roleInput}
          setRoleInput={setRoleInput}
          userGroupInput={userGroupInput}
          setUserGroupInput={setUserGroupInput}
        />
      )
    })
  }

  const renderBtnEdit = () => {
    if (!editableCustomer) return;

    return (
      <>
        <button
          onClick={handleCancel}
          className={`btn fw-bold shadow-0 btn-cancel text-decoration-underline d-block `}
        >
          CANCEL
        </button>
        <button
          onClick={handleSaveCustomer}
          className={`btn fw-bold shadow-0 pe-0 text-decoration-underline d-block`}
        >
          SAVE
        </button>
      </>
    )
  }

  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">CUSTOMERS CMS</h1>
        <div className="input-group ms-auto page-header__input-search w-50">
          <input
            onChange={(e) => setSearch(e.target.value)}
            value={search}
            className="form-control"
            type="text"
            placeholder="Search..."
          />
        </div>
        <button
          className={`btn shadow-0 ps-xl-5 pe-0 m-0 ms-xl-5 ${!isSortAsc && "sort-desc"
            }`}
          onClick={() => toggleSortAsc(!isSortAsc)}
        >
          Name
          <span className="btn-icon-append ms-3">
            <svg
              width="12"
              height="16"
              viewBox="0 0 12 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.53033 0.46967C6.23744 0.176777 5.76256 0.176777 5.46967 0.46967L0.696699 5.24264C0.403806 5.53553 0.403806 6.01041 0.696699 6.3033C0.989592 6.59619 1.46447 6.59619 1.75736 6.3033L6 2.06066L10.2426 6.3033C10.5355 6.59619 11.0104 6.59619 11.3033 6.3033C11.5962 6.01041 11.5962 5.53553 11.3033 5.24264L6.53033 0.46967ZM6.75 16L6.75 1H5.25L5.25 16H6.75Z"
                fill="#414345"
              />
            </svg>
          </span>
        </button>
      </div>
      <div className="page-body">
        <div className="table-responsive-custom">
          <table className="table-custom">
            <thead>
              <tr
                style={{
                  display: "table",
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <th className="col-2">Name</th>
                <th className="col-2">Email</th>
                <th className="col-1">Number</th>
                <th className="col-2">Company</th>
                <th>Role</th>
                <th>Type</th>
                <th>Liked</th>
                <th>Notes</th>
                <th>Active</th>
                <th> </th>
              </tr>
            </thead>
            <tbody
              style={{
                display: "block",
                maxHeight: "calc(100vh - 400px)",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {renderListCustomers()}
            </tbody>
          </table>
        </div>
      </div>
      <div className="page-footer d-flex justify-content-end">
        <div className="d-flex flex-grow-1">
          <button
            style={{ padding: "7px 12px" }} className="btn-outline fw-bold"
            onClick={() => setIsShowAddNewCustomerModal(true)}
          >
            ADD NEW CUSTOMER
          </button>
        </div>
        {renderBtnEdit()}
      </div>
      <NotesModal
        notes={notes}
        isEditNotes={isEditNotes}
        setIsEditNotes={setIsEditNotes}
        handleSaveNotesModal={handleSaveNotesModal}
        handleHideNotesModal={handleHideNotesModal}
      />

      <AddNewCustomerModal
        show={isShowAddNewCustomerModal}
        setIsShowAddNewCustomerModal={setIsShowAddNewCustomerModal}
      />
    </div>
  );
};
export default CMSCustomers;

