import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { PAGES } from "../../constants/options";
import { reqSetPage, reqIsLoadedIntroVideo } from "../../reduxs/home/action";
import './index.scss';

const VideoIntro = () => {
  const videoRef = useRef(null);
  const dispatch = useDispatch();
  const [isMuted, setIsMuted] = useState(false);
  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = isMuted
    }
  },[isMuted]);

  useEffect(() => {
    if (videoRef.current && videoRef.current.readyState === 4) {
      videoRef.current.play();
    }
  },[videoRef]);

  const onMuteGuidedTourVideo = () => {
    setIsMuted(!isMuted);
  }

  const onSkipVideo = () => {
    dispatch(reqSetPage(PAGES.INTERACTIVE_TOUR));
  }


  return (
    <div className={`wrap-video-intro`}>
      <video
        ref={videoRef}
        autoPlay={true}
        preload="auto"
        id="intro-video-2"
      >
        <source src="/uploads/videos/RES2562_Eynesbury_Edit_001_Animatic.mp4" type="video/mp4" />
      </video>
      <div className='wrap-btn-control-video d-flex flex-column'>
        <button className='btn-bordered mb-3' onClick={onMuteGuidedTourVideo}>
          <div className='d-flex flex-row h-100'>
            <div className='btn-arrow'><img className='img-fluid' src="/icons/right-vector.svg" alt="" /></div>
            <div className='btn-text'>
              {
                isMuted ? <span>AUDIO ON</span> : <span>AUDIO OFF</span>
              }
            </div>
          </div>
        </button>
        <button className='btn-bordered' onClick={onSkipVideo}>
          <div className='d-flex flex-row h-100'>
            <div className='btn-arrow'><img className='img-fluid' src="/icons/right-vector.svg" alt="" /></div>
            <div className='btn-text'>
              <span>SKIP</span>
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}

export default VideoIntro;
