import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import jwtAuth from "../../apis/utils/jwtAuth";

const TopNavCMS = (props) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const getActiveClass = (path) => {
    return path === pathname ? "active" : "";
  };

  const logout = () => {
    jwtAuth.removeToken();
    history.push("/holding");
  };
  return (
    <div className="wrap-top-nav show">
      <div className="nav-menu">
        <ul className="left">
          <li className={getActiveClass("/cms/properties")}>
            <Link to="/cms/properties" className="text-decoration-none">
              <div>PROPERTIES</div>
            </Link>
          </li>
          <li className={getActiveClass("/cms/customers")}>
            <Link to="/cms/customers" className="text-decoration-none">
              <div>CUSTOMERS</div>
            </Link>
          </li>
        </ul>
        <ul className="nav-cube">
          <div className="cube-scene-wrapper">
            <div className="cube-scene" onClick={() => history.push("/")}>
              <div className="cube">
                <div className="cube__face cube__face--front" />
                <div className="cube__face cube__face--back" />
                <div className="cube__face cube__face--right" />
                <div className="cube__face cube__face--left" />
                <div className="cube__face cube__face--top" />
                <div className="cube__face cube__face--bottom" />
              </div>
            </div>
          </div>
        </ul>
        <ul className="right">
          <li className={getActiveClass("/cms/frequently-asked-questions")}>
            <Link
              to="/cms/frequently-asked-questions"
              className="text-decoration-none"
            >
              <div>FAQS</div>
            </Link>
          </li>
          <li onClick={() => logout()}>
            <div>LOG OUT</div>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default TopNavCMS;
