import {
  GET_LIST_CUSTOMERS,
  GET_LIST_UNITS,
  SET_EDITABLE_CUSTOMER,
  SET_EDITABLE_CUSTOMER_NOTE,
  SET_EDITABLE_PROPERTY_COLUMN,
  ADD_ONE_CUSTOMER,
} from './type';

const initialState = {
  customers: [],
  units: [],
  editableCustomer: null,
  editablePropertyColumn: null,
  editableCustomerNote: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_LIST_CUSTOMERS:
      return {
        ...state,
        customers: action.data.data
      };
    case GET_LIST_UNITS:
      return {
        ...state,
        units: action.data.data
      };
    case SET_EDITABLE_CUSTOMER:
      return {
        ...state,
        editableCustomer: action.data
      };
    case SET_EDITABLE_CUSTOMER_NOTE:
      return {
        ...state,
        editableCustomerNote: action.data
      };
    case SET_EDITABLE_PROPERTY_COLUMN:
      return {
        ...state,
        editablePropertyColumn: action.data
      };
    case ADD_ONE_CUSTOMER:
      return {
        ...state,
        customers: [...state.customers, action.data]
      }
    default:
      return state;
  }
};
