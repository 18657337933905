import React, { useEffect } from "react";

const CMSFrequentlyAQS = () => {
  return (
    <div className="page-container">
      <div className="page-header d-flex align-items-center">
        <h1 className="f-title mb-0">FAQS CMS</h1>
      </div>
    </div>
  );
};
export default CMSFrequentlyAQS;
