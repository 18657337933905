import React from "react";
import { useEffect } from "react";
import { Accordion, Card } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { reqGetCategoriesList, reqSetActiveLocation } from "../../reduxs/category/action";
import { reqSetExploreModal } from "../../reduxs/explore-modal/action";
import { reqSetIsShowGalleryModal } from "../../reduxs/home/action";
import ContextAwareToggle from './ContextAwareToggle';

import './index.scss';

const LegendSidebar = () => {
  const activeType = useSelector((state) => state.poi.activeType);
  const categories = useSelector((state) => state.category.categories);
  const isShowFuture = useSelector((state) => state.home.isShowFuture);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(reqGetCategoriesList());
  }, []);

  const getShowClass = (key) => {
    return activeType == key ? 'show' : '';
  }

  const handleSelectLocation = (item) => {
    dispatch(reqSetActiveLocation(item.id));
    dispatch(reqSetIsShowGalleryModal(true));
    dispatch(reqSetExploreModal(item.link));
  }

  const renderListLocations = (item) => {
    return (item.locations || []).map(item => {

      return (
        <li onClick={() => handleSelectLocation(item)} key={item.id}>{item.index} {item.name}</li>
      )
    });
  }

  const renderListCategory = () => {
    return categories.map((item) => {
      if (!isShowFuture && item.name == 'FUTURE DEVELOPMENTS') return;

      return (
        <Card key={item.id} className="card-item">
          <div className="list-group">
            <ContextAwareToggle activeType={activeType} eventKey={item.id}>
              <div className="d-flex justify-content-between">
                <div className="category-name">
                  <span className="color-key" style={{backgroundColor: `${item.color}`}} />
                  <span>{item.name}</span>
                </div>
                {
                  activeType == item.id
                  ? <img className="img-fluid" src="./icons/up-arrow.svg" alt="" />
                  : <img className="img-fluid" src="./icons/down-arrow.svg" alt="" />
                }
              </div>
            </ContextAwareToggle>
            <Accordion.Collapse eventKey={item.id} className={`mb-4 ${getShowClass(item.id)}`}>
              <ul className="wrap-list-item">
                {renderListLocations(item)}
              </ul>
            </Accordion.Collapse>
          </div>
        </Card>
      )
    })
  }

  const renderIntroLegendSidebar = () => {
    if(activeType) return;

    return (
      <div className="card-footer">
        <ul className="list-group">
          <li className="list-group-item">
            <div className="color walking-tracks"/>
            Walking Tracks
          </li>
          {
            isShowFuture && (
              <li className="list-group-item">
                <div className="color mountain-bike-trails"/>
                Mountain Bike Trails
              </li>
            )
          }
        </ul>
      </div>
    )
  }

  return (
    <>
      <div className="wrap-legend-sidebar">
        <h2 className="card-title">AMENITIES</h2>
        <div className="card-body">
          <Accordion>
            {renderListCategory()}
          </Accordion>
        </div>
        {renderIntroLegendSidebar()}
      </div>
    </>
  );
}

export default LegendSidebar;
