import React from "react";
import './index.scss';
import Fancybox from "./fancy-box";
import galleryApi from '../../apis/api/gallery';
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";

const HomeGallery = () => {
  const [gallery, setGallery] = useState();
  const [isShowFancyBox, setIsShowFancyBox] = useState(false);

  const cardStyles = [];

  useEffect(() => {
    if(isShowFancyBox) {
      const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
      fancyBoxRef.on('destroy', (e) => setIsShowFancyBox(false));
    }
  }, [isShowFancyBox])

  useEffect(async () => {
    const res = await galleryApi.getGalleryDetail('60cb329ee76f1f0016defcff');
    setGallery(res.data);
  }, []);

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.next();
  }

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.prev();
  }

  const onCloseFancyBox = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    setIsShowFancyBox(false);
    fancyBoxRef?.close();
  }

  const getRandomIn = (max) => {
    return Math.floor(Math.random() * max);
  }

  const getCardClass = (index, style) => {
    if (style.cardTalls.includes(index)) return 'card-tall';

    if (style.cardWides.includes(index)) return 'card-wide';

    return;
  }

  const styles = [
    {
      cardWides: [3],
      cardTalls: [1]
    },
    {
      cardWides: [1],
      cardTalls: [3]
    },
    {
      cardWides: [0,2],
      cardTalls: []
    },
    {
      cardWides: [2],
      cardTalls: [3]
    },
    {
      cardWides: [0],
      cardTalls: [2]
    },
    {
      cardWides: [0],
      cardTalls: [3]
    },
  ];

  const renderStyleOne = (items) => {
    const styleIndex = getRandomIn(6);
    const style = styles[styleIndex];

    return (
      (items || []).map((item, index) => {
        return (
          <div key={index} onClick={() => setIsShowFancyBox(true)} className={`card border-0 ${getCardClass(index, style)}`} style={{backgroundImage: `url(${item.thumbnail})`}} data-fancybox="gallery" data-src={item.path} />
        )
      })
    )
  }

  return (
    <>
      <div className="wrap-home-gallery">
        <div className="home-gallery-header container">
          <h1>IMAGE<br />GALLERY</h1>
          <div className="wrap-image-list">
            <Fancybox setIsShowFancyBox={setIsShowFancyBox} options={{ infinite: true, mainClass: 'wrap-fancy-box', showNavArrows: false }}>
              <div className="image-mosaic">
                {
                  _.chunk((gallery?.media || []), 8).map((items) => {
                    return renderStyleOne(items);
                  })
                }
              </div>
            </Fancybox>
          </div>
        </div>

      </div>
      {
        isShowFancyBox && (
          <>
            <div className="wrap-btn-fancy">
              <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
                <img className="img-fluid" src="./icons/left-arrow.svg" alt="" />
              </div>
              <div className="btn-next cursor-pointer" onClick={onNextImage}>
                <img className="img-fluid" src="./icons/right-arrow.svg" alt="" />
              </div>
            </div>
            <div className="btn-close-fancy cursor-pointer" onClick={onCloseFancyBox}>
              <img className="img-fluid" src="./icons/close.svg" alt="" />
            </div>
          </>
        )
      }
    </>
  );
}

export default HomeGallery;
