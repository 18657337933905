import * as Types from './type';
import categoryApi from '../../apis/api/category';

const actGetCategoriesList = (data) => ({
  type: Types.GET_CATEGORIES_LIST,
  data,
});

const actSetActiveLocation = (data) => ({
  type: Types.SET_ACTIVE_LOCATION,
  data
});

export const reqGetCategoriesList = () => (dispatch) => {
  return categoryApi
    .getCategoryList()
    .then((data) => {
      dispatch(actGetCategoriesList(data));
    })
};

export const reqSetActiveLocation = (data) => (dispatch) => {
  return dispatch(actSetActiveLocation(data));
}
