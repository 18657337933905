export const SET_UNIT_QUERY = 'SET_QUERY_UNIT';
export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export const GET_UNIT_AREA_LIST = 'GET_UNIT_AREA';

export const FILTER_GROSS_RENT = 'FILTER_GROSS_RENT';
export const FILTER_CENTRE = 'FILTER_CENTRE';
export const FILTER_LEVEL = 'FILTER_LEVEL';
export const FILTER_AREA = 'FILTER_AREA';
export const FILTER_PRECINCT = 'FILTER_PRECINCT';

export const SET_IS_SHOW_FLOORPLAN = 'IS_SHOW_FLOORPLAN';
export const SET_IS_SHOW_GALLERY = 'IS_SHOW_GALLERY';
export const SET_IS_SHOW_UNIT_DETAIL = 'IS_SHOW_UNIT_DETAIL';
export const SET_SELECTED_UNIT = 'SET_SELECTED_UNIT';
export const SET_IS_SHOW_FILTER = 'SET_IS_SHOW_FILTER';
export const SET_IS_TRANSPARENT = 'SET_IS_TRANSPARENT';
export const SET_IS_SHOW_PRECINCT_DETAIL = 'SET_IS_SHOW_PRECINCT_DETAIL';
