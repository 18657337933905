import React from "react";
import { useDispatch } from "react-redux";
import { reqSetIsShowIntroFutureBtn } from "../../reduxs/home/action";

const IntroFutureBtn = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(reqSetIsShowIntroFutureBtn(false));
  }

  return (
    <div className="bg-trim" onClick={onClose}>
      <div className="wrap-intro-future-btn">
        <span>Want to see our vision for
          Eynesbury? Click this button for
          something to look forward to!</span>
      </div>
    </div>
  )
}

export default IntroFutureBtn;
