import React, { useContext } from "react";
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { reqSetActivePoi, reqSetActiveType } from "../../reduxs/locations/action";
import { reqSetActiveLocation } from "../../reduxs/category/action";

const ContextAwareToggle = (props) => {
  const { children, eventKey, callback, activeType } = props;
  const currentEventKey = useContext(AccordionContext);
  const dispatch = useDispatch();

  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    callback && callback(eventKey);
    if (eventKey == currentEventKey) {
      dispatch(reqSetActiveType(''));
      dispatch(reqSetActivePoi(''));
      dispatch(reqSetActiveLocation(''));
    } else {
      dispatch(reqSetActiveType(eventKey));
    }
  });

  const getClassName = () => {
    if (activeType) {
      return activeType == eventKey ? 'heading-active' : 'heading has-selected-item';
    }

    return 'heading';
  }

  return (
    <li
      onClick={decoratedOnClick}
      className={`list-group-item ${getClassName()}`}
    >
      {children}
    </li>
  );
}

export default ContextAwareToggle;
