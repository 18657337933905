import ApiClient from '../apiClient';
import { authFetcher } from '../utils/fetcher';
import endPoint from '../endPoint';

const client = new ApiClient(authFetcher);

const getLocationList = (data) => client.get(endPoint.LOCATION_GET_LIST, data);

export default {
  getLocationList,
};
