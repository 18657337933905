import { Html } from "@react-three/drei";
import React from "react";
import { useSelector } from "react-redux";
import { LAYERS } from "../../constants/options";
import { useDispatch } from 'react-redux';
import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveTransportOption, reqSetIsShowGalleryModal, reqSetIsShowHotspotModal, reqSetSelectedHotspot } from '../../reduxs/home/action';
import { reqSetActivePrecinctID } from '../../reduxs/transport-options/action';
import * as THREE from 'three';

const Annotations = (props) => {
  const { locations, controls } = props;

  const dispatch = useDispatch();

  const isShowFuture = useSelector((state) => state.home.isShowFuture);
  const activeType = useSelector((state) => state.poi.activeType);
  const categories = useSelector((state) => state.category.categories);
  const activeLocation = useSelector((state) => state.category.activeLocation);

  let filterGroups = [];
  let visibleGroups = [];
  let wantedLayer = isShowFuture ? LAYERS.FUTURE : LAYERS.EXISTING;

  const nameGroups = [
    {keys: [7,8,9,28], locations: [], id: -1},
    {keys: [25,29], locations: [], id: -2},
    {keys: [1,2,3,4,5,6,13,15,16,23,24,31], locations: [], id: -3}
  ]

  locations.forEach(location => {
    if (location.index == 1) {
      nameGroups[2].position = location.position;
    } else if (location.index == 7) {
      nameGroups[0].position = location.position;
    } else if (location.index == 25) {
      nameGroups[1].position = location.position;
    }
  });

  if (activeType) {
    if (activeLocation) {
      locations.forEach(location => {
        if (location.id == activeLocation) {
          filterGroups.push(location);
          let added = false;
          nameGroups.forEach(group => {
            if (group.keys.includes(location.index)) {
              group.locations.push(location);
              added = true;
            }
          });
          if (!added) {
            nameGroups.push({keys: [location.index], locations: [location], id: location.index, position: location.position});
          }
        }
      });
    } else {
      categories.forEach(category => {
        if (category.id == activeType) {
          category.locations.forEach( location => {
            if (location.layer.includes(wantedLayer)) {
              filterGroups.push(location);
              let added = false;
              nameGroups.forEach(group => {
                if (group.keys.includes(location.index)) {
                  group.locations.push(location);
                  added = true;
                }
              });
              if (!added) {
                nameGroups.push({keys: [location.index], locations: [location], id: location.index, position: location.position});
              }
            }
          })
        }
      });
    }
  } else {
    locations.forEach(location => {
      if (location.layer.includes(wantedLayer)) {
        filterGroups.push(location);
        let added = false;
        nameGroups.forEach(group => {
          if (group.keys.includes(location.index)) {
            group.locations.push(location);
            added = true;
          }
        });
        if (!added) {
          nameGroups.push({keys: [location.index], locations: [location], id: location.index, position: location.position});
        }
      }
    });
  }

  /*
  locations.forEach(location => {
    if (location.layer.includes(wantedLayer)) {
      visibleGroups.push(location);
    }
  });

  const filterGroupsIds = filterGroups.map((hotspot) => hotspot.id);*/

  return (
    <group>
      {nameGroups.filter(group => group.locations.length > 0).map((group) => {
        group.locations.sort((a,b) => a.index - b.index);
        const locations = group.locations;
        return (
          <Html className='hotspot-parent' 
          key={group.id} 
          zIndexRange={[10, 0]} 
          sprite={true} 
          distanceFactor={50} 
          position={[group.position.x, group.position.z, group.position.y]}>
            <div className="hotspot-content">
              <div className='hotspot-grid-container'>
                {locations.map((hotspot, index) => (
                  <div 
                  key={hotspot.index}
                  className="hotspot-content-item" 
                  style={{backgroundColor: `${hotspot.category.color}`, opacity: 1.0 }} 
                  onPointerOver={() => controls.current && controls.current.setCursorStyle('pointer') } 
                  onScroll={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }} 
                  onPointerOut={() => controls.current && controls.current.setCursorStyle('grab') } 
                  onWheel={(event) => {
                    if (controls && controls.current) {
                      controls.current.forceMouseWheel(event);
                    }
                    event.preventDefault();
                    event.stopPropagation();
                  }} 
                  onClick={ (event) => {
                    dispatch(reqSetIsShowGalleryModal(true));
                    dispatch(reqSetSelectedHotspot(hotspot));
                    dispatch(reqSetExploreModal(hotspot.link));
                    }}>
                      <div className='hotspot-title'>
                        <span>{hotspot.index}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Html>
        );
        })}
    </group>
  );
}

export default Annotations;
