import * as Types from './type';
import customerApi from '../../apis/api/customer';
import unitApi from '../../apis/api/unit';

const actSetListCustomers = (data) => ({
  type: Types.GET_LIST_CUSTOMERS,
  data,
});

export const actSetListUnits = (data) => ({
  type: Types.GET_LIST_UNITS,
  data,
});

export const actSetEditableCustomer = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER,
  data,
});

export const actSetEditableCustomerNote = (data) => ({
  type: Types.SET_EDITABLE_CUSTOMER_NOTE,
  data,
});

export const actSetEditablePropertyColumn = (data) => ({
  type: Types.SET_EDITABLE_PROPERTY_COLUMN,
  data,
});

export const actAddOneCustomer = (data) => ({
  type: Types.ADD_ONE_CUSTOMER,
  data,
})

export const reqSetEditableCustomer = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomer(data));
}

export const reqSetEditableCustomerNote = (data) => (dispatch) => {
  return dispatch(actSetEditableCustomerNote(data));
}

export const reqSetEditablePropertyColumn = (data) => (dispatch) => {
  return unitApi
    .putUnitUpdate(data.id, data)
    .then(res => {
      dispatch(actSetEditablePropertyColumn(res));
    })
}

export const reqGetListCustomers = (query) => (dispatch) => {
  return customerApi
    .getCustomerList(query)
    .then((data) => {
      dispatch(actSetListCustomers(data));
    })
};

export const reqGetListUnits = (query) => (dispatch) => {
  return unitApi
    .getUnitList(query)
    .then((data) => {
      dispatch(actSetListUnits(data));
    })
};

